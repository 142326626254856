import { Box, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Handle, Position, NodeProps, useReactFlow } from 'reactflow';

import { ReactComponent as CameraIcon } from 'assets/icons/graph/events/camera.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/graph/events/email.svg';
import { ReactComponent as GenericIcon } from 'assets/icons/graph/events/generic.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/graph/events/phone.svg';

import { ReactComponent as CollapseIcon } from 'assets/icons/graph/graphCollapsedIcon.svg';
import { ReactComponent as UnCollapseIcon } from 'assets/icons/graph/graphUncollapsedIcon.svg';
import theme from 'core/theme';
import { ComponentDesign } from '../../types';
import { useGraphRender } from 'store/graphRender/hooks';
import { useGraphRenderLayoutUtils } from '../../hooks/utils/useGraphRenderLayoutUtils';
import _ from 'lodash';

const iconStyle = { width: '70%', height: '70%', stroke: 'white', fill: theme.palette.primary.darkBlueHigh };

export const EventBridgeNode: FC<NodeProps> = (props: NodeProps) => {
  const { onHide } = useGraphRenderLayoutUtils();
  const reactFlow = useReactFlow();
  const { data, id, selected, dragging } = props;
  const { bridgeLabel, _collapsed, connectionsCount, metaData } = data;
  const { eventType } = metaData;
  const collapsed = _collapsed == null ? true : _collapsed;
  const HandlerStyle = { display: 'none' };

  const {
    data: { customDesignMap, defaultDesign },
    setSelectedNode,
    setSelectedNodeFromSearchBar,
  } = useGraphRender();

  let design = defaultDesign ? defaultDesign : ComponentDesign.STANDARD;

  if (customDesignMap[id]) {
    design = customDesignMap[id];
  }

  useEffect(() => {
    if (selected) {
      const node = reactFlow.getNode(id);
      const nodeTmp = _.cloneDeep(node);
      setSelectedNode(nodeTmp);
      setSelectedNodeFromSearchBar(nodeTmp);
    }
  }, [dragging, id, reactFlow, selected, setSelectedNode, setSelectedNodeFromSearchBar]);

  const onClickHandler = useCallback(() => {
    onHide(id, !collapsed, { desiredEdgedLength: 25 });
  }, [collapsed, id, onHide]);

  const borderColor = useMemo(() => {
    if (design === ComponentDesign.SEARCHING || design === ComponentDesign.DUPLICATE_INACTIVE) {
      return theme.palette.neutral.subtone3;
    }
    return theme.palette.primary.darkBlueHigh;
  }, [design]);

  const shouldRenderIcon = useMemo(() => {
    if (design === ComponentDesign.SEARCHING || design === ComponentDesign.DUPLICATE_INACTIVE) {
      return false;
    }
    return true;
  }, [design]);

  const shouldRenderExpandableIcon = useMemo(() => {
    if (
      design === ComponentDesign.SEARCHING ||
      design === ComponentDesign.HIGHLIGH ||
      ComponentDesign.DUPLICATE_INACTIVE
    ) {
      return false;
    }
    return true;
  }, [design]);

  const getIcon = useCallback((eventType: string) => {
    if (eventType === 'Call') {
      return <PhoneIcon style={iconStyle} />;
    } else if (eventType === 'Email') {
      return <EmailIcon width={'70%'} height={'70%'} />;
    } else if (eventType === 'Meeting') {
      return <CameraIcon style={{ ...iconStyle, stroke: theme.palette.primary.darkBlueHigh }} />;
    } else {
      return <GenericIcon style={iconStyle} />;
    }
  }, []);

  const icon = useMemo(() => getIcon(eventType), [eventType, getIcon]);

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: borderColor,
        backgroundColor: theme.palette.neutral.white,
        borderRadius: '100%',
        width: '100%',
        height: '100%',
      }}
      onClick={onClickHandler}
    >
      {shouldRenderIcon && (
        <Box width={'100%'} height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          {icon}
        </Box>
      )}

      {shouldRenderExpandableIcon && (
        <Box position={'absolute'} bottom={'-20%'} left={'-2%'} sx={{ color: theme.palette.primary.gray }}>
          {collapsed === true ? (
            <CollapseIcon width={'10px'} height={'10px'} />
          ) : (
            <UnCollapseIcon width={'10px'} height={'10px'} />
          )}
        </Box>
      )}
      {collapsed && (
        <Box
          position={'absolute'}
          top={'-5%'}
          right={'-5%'}
          width={'5px'}
          height={'5px'}
          borderRadius={'100%'}
          padding={'3px'}
          border={`1px solid ${theme.palette.primary.darkBlueHigh}`}
          sx={{
            color: theme.palette.primary.darkBlueHigh,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.neutral.white,
          }}
        >
          <Typography variant="labelRegular12" sx={{ fontWeight: 'bold', fontSize: '5px' }}>
            {connectionsCount / 2}
          </Typography>
        </Box>
      )}
      <Handle position={Position.Right} type="source" style={HandlerStyle} />
      <Handle position={Position.Left} type="target" style={HandlerStyle} />
    </Box>
  );
};
