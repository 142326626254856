import { useState } from 'react';
import { Box, Card, Typography, Grid } from '@mui/material';

const sources = [
  { name: 'Salesforce', logo: 'https://cdn-icons-png.flaticon.com/128/5968/5968914.png' },
  {
    name: 'Marketo',
    logo: 'https://cdn.iconscout.com/icon/free/png-256/free-marketo-logo-icon-download-in-svg-png-gif-file-formats--technology-social-media-vol-1-pack-logos-icons-3030046.png?f=webp&w=256',
  },
  { name: 'Import CSV', logo: 'https://cdn0.iconfinder.com/data/icons/file-types-26/52/csv-file-1024.png' },
  { name: 'HubSpot', logo: 'https://logowik.com/content/uploads/images/hubspot5453.logowik.com.webp' },
  { name: 'LinkedIn', logo: 'https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png' },
];

interface ChooseSourceProps {
  onSourceSelect: (source: string) => void;
}

export const ChooseSource = ({ onSourceSelect }: ChooseSourceProps) => {
  const [selectedSource, setSelectedSource] = useState<string | null>(null);

  const handleSelect = (source: string) => {
    setSelectedSource(source);
    onSourceSelect(source);
  };

  return (
    <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" mb={1}>
        Choose the first source you want to integrate
      </Typography>
      <Typography variant="body2" color="neutral.n400" align="center" mb={4}>
        You can integrate one source at a time. After setting-up this source, you can add others or you can personalize
        your graph view.
      </Typography>
      <Grid container spacing={2} justifyContent="center" sx={{ width: '45%' }}>
        {sources.map((source) => (
          <Grid item key={source.name} xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Card
              onClick={() => handleSelect(source.name)}
              sx={{
                cursor: 'pointer',
                width: '211px',
                height: '118px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                border: selectedSource === source.name ? '1px solid blue' : '1px solid transparent',
                '&:hover': {
                  border: '1px solid blue',
                },
              }}
            >
              <Box>
                <img src={source.logo} key={source.name} height={50} />
              </Box>
              <Typography variant="labelMedium14">{source.name}</Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
