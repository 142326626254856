import theme from 'core/theme';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Handle, Position, NodeProps, useReactFlow } from 'reactflow';
import { useGraphRender } from 'store/graphRender/hooks';
import _ from 'lodash';
import { ReactComponent as CameraIcon } from 'assets/icons/graph/events/camera.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/graph/events/emailNode.svg';
import { ReactComponent as GenericIcon } from 'assets/icons/graph/events/generic.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/graph/events/phone.svg';
import { ToolBar } from './components/ToolBar';

import { motion } from 'framer-motion';
import { Box } from '@mui/material';

const iconStyle = { width: '60%', height: '60%', stroke: 'white' };

export const EventRecordNode: FC<NodeProps> = (props: NodeProps) => {
  const reactFlow = useReactFlow();
  const { data, id, selected } = props;
  const { Type: eventType } = data;

  const { setSelectedNode, setSelectedNodeFromSearchBar } = useGraphRender();

  useEffect(() => {
    if (selected) {
      const node = reactFlow.getNode(id);
      const nodeTmp = _.cloneDeep(node);
      setSelectedNode(nodeTmp);
      setSelectedNodeFromSearchBar(nodeTmp);
    }
  }, [id, reactFlow, selected, setSelectedNode, setSelectedNodeFromSearchBar]);

  const HandlerStyle = { display: 'none' };

  const getBackgroundColor = useCallback((eventType: string) => {
    if (eventType === 'Call') {
      return theme.palette.violet.subtone1;
    } else if (eventType === 'Email') {
      return theme.palette.violet.subtone1;
    } else if (eventType === 'Meeting') {
      return theme.palette.green.main;
    } else {
      return theme.palette.orange.n200;
    }
  }, []);

  const nodeColor = useMemo(() => getBackgroundColor(eventType), [eventType, getBackgroundColor]);

  const getIcon = useCallback(
    (eventType: string) => {
      if (eventType === 'Call') {
        return <PhoneIcon style={{ ...iconStyle, fill: nodeColor }} />;
      } else if (eventType === 'Email') {
        return <EmailIcon style={{ ...iconStyle, fill: nodeColor }} />;
      } else if (eventType === 'Meeting') {
        return <CameraIcon style={{ ...iconStyle, fill: nodeColor }} />;
      } else {
        return <GenericIcon style={{ ...iconStyle, fill: nodeColor }} />;
      }
    },
    [nodeColor],
  );

  const icon = useMemo(() => getIcon(eventType), [eventType, getIcon]);

  return (
    <motion.div
      animate={{ scale: [0, 1.5, 1] }}
      style={{
        backgroundColor: nodeColor,
        height: '100%',
        width: '100%',
        borderRadius: '100%',
        zIndex: 1,
      }}
    >
      <ToolBar nodeProps={props} renderLockUnlockWhiteEdgeButton />
      <Box width={'100%'} height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        {icon}
      </Box>
      <Handle position={Position.Right} type="source" style={HandlerStyle} />
      <Handle position={Position.Left} type="target" style={HandlerStyle} />
    </motion.div>
  );
};
