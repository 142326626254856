import { ChooseSource } from './pages/ChooseSource/ChooseSource';
import UploadFiles from './pages/UploadFiles/UploadFiles';
import { SelectObjects } from './pages/SelectObjects/SelectObjects';
import { ObjectSelection } from './pages/SelectObjects/ObjectSelection/ObjectSelection';
import SavedSchemaMapping from './pages/SelectObjects/SavedSchemaMapping/SavedSchemaMapping';
import { FieldMapping } from './pages/FieldMapping/FieldMapping';
import MergePolicies from './pages/MergePolicies/MergePolicies';
import ReviewData from './pages/ReviewData/ReviewData';
import Confirmation from './pages/Confirmation/Confirmation';

interface ExtraData {
  step: string;
  extraMessage?: React.ReactNode;
  extraButton?: React.ReactNode;
}

interface HiddenButtons {
  step: string;
  isHidden?: boolean;
}

interface StepsConfigProps {
  handleSourceSelect: (source: string) => void;
  selectedSource: string | null;
  isAuthenticated: boolean;
  handleAuthenticate: () => void;
  isLoading: boolean;
  selectedCard: string;
  handleCardSelect: (card: string) => void;
  extraData: ExtraData;
  handleExtraDataUpdate: (data: ExtraData) => void;
  handleHiddenButtons: (data: HiddenButtons) => void;
}
interface ExtraData {
  step: string;
  extraMessage?: React.ReactNode;
  extraButton?: React.ReactNode;
}

interface HiddenButtons {
  step: string;
  isHidden?: boolean;
}

interface StepsConfigProps {
  handleSourceSelect: (source: string) => void;
  selectedSource: string | null;
  isAuthenticated: boolean;
  handleAuthenticate: () => void;
  isLoading: boolean;
  selectedCard: string;
  handleCardSelect: (card: string) => void;
  extraData: ExtraData;
  handleExtraDataUpdate: (data: ExtraData) => void;
  handleHiddenButtons: (data: HiddenButtons) => void;
  selectedItems: { records: string[]; activities: string[] };
  setSelectedItems: React.Dispatch<React.SetStateAction<{ records: string[]; activities: string[] }>>;
  handleSelectedPolicy: (source: string) => void;
  selectedPolicy: string | null;
  selectedMappingCards: { [key: string]: boolean };
  handleSelectedMappingCards: (selectedCards: { [key: string]: boolean }) => void;
}

export const stepsConfig = ({
  handleSourceSelect,
  selectedSource,
  selectedItems,
  setSelectedItems,
  isAuthenticated,
  handleAuthenticate,
  isLoading,
  selectedCard,
  handleCardSelect,
  extraData,
  handleExtraDataUpdate,
  handleHiddenButtons,
  selectedPolicy,
  handleSelectedPolicy,
  selectedMappingCards,
  handleSelectedMappingCards,
}: StepsConfigProps) => {
  const shouldShowModal = !isAuthenticated && selectedSource !== 'Import CSV';
  const selectedStepLabel = selectedCard === 'Object Selection' ? 'Object Selection' : 'Saved Schema Mapping';

  const steps = [
    { label: 'Choose Source', validate: () => selectedSource !== null },
    { label: 'Select Objects', validate: () => true },
    {
      label: selectedStepLabel,
      validate: () =>
        selectedCard === 'Object Selection'
          ? selectedItems.records.length > 0 && selectedItems.activities.length > 0
          : Object.keys(selectedMappingCards).some((card) => selectedMappingCards[card]),
    },
    { label: 'Field Mapping', validate: () => true },
    { label: 'Merge Policies', validate: () => selectedPolicy !== null },
    { label: 'Review', validate: () => true },
    { label: 'Confirmation', validate: () => true },
  ];

  const stepContent = [
    <ChooseSource key="choose-source" onSourceSelect={handleSourceSelect} />,
    <SelectObjects
      key="select-objects"
      isLoading={isLoading}
      showModal={shouldShowModal}
      handleAuthenticate={handleAuthenticate}
      selectedCard={selectedCard}
      handleCardSelect={handleCardSelect}
    />,
    selectedCard === 'Object Selection' ? (
      <ObjectSelection key="object-selection" onSelectionChange={setSelectedItems} />
    ) : (
      <SavedSchemaMapping key="saved-schema-mapping" onMappingCardSelect={handleSelectedMappingCards} />
    ),
    <FieldMapping key="field-mapping" onExtraData={handleExtraDataUpdate} />,
    <MergePolicies key="merge-policies" onPolicySelect={handleSelectedPolicy} />,
    <ReviewData key="review-data" />,
    <Confirmation key="confirmation" onHiddenButtons={handleHiddenButtons} />,
  ];

  if (selectedSource === 'Import CSV') {
    steps.splice(1, 0, { label: 'Upload File', validate: () => true });
    stepContent.splice(1, 0, <UploadFiles key="upload-file" />);
  }

  return { steps, stepContent };
};
