import { useEffect, useState } from 'react';
import { Card, CardContent, Box, Typography, LinearProgress } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import DescriptionIcon from '@mui/icons-material/Description';

import AuthModal from './AuthModal';

interface SelectObjectsProps {
  isLoading: boolean;
  showModal: boolean;
  handleAuthenticate: () => void;
  loadingTime?: number;
  selectedCard: string;
  handleCardSelect: (card: string) => void;
}

export const SelectObjects = ({
  isLoading: initialLoading,
  showModal,
  handleAuthenticate,
  loadingTime = 3000,
  selectedCard,
  handleCardSelect,
}: SelectObjectsProps) => {
  const [isLoading, setIsLoading] = useState(initialLoading);
  const [progress, setProgress] = useState(0);
  const [openModal, setOpenModal] = useState(showModal);

  const handleModalAuthenticate = () => {
    handleAuthenticate();
    setOpenModal(false);
  };

  useEffect(() => {
    if (!openModal && initialLoading) {
      setIsLoading(true);
      const increment = 100 / (loadingTime / 100);
      const progressInterval = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + increment;
          if (newProgress >= 100) {
            clearInterval(progressInterval);
            setIsLoading(false);
            return 100;
          }
          return newProgress;
        });
      }, 100);
      return () => clearInterval(progressInterval);
    }
  }, [initialLoading, loadingTime, openModal]);

  const renderProgressContent = () => (
    <>
      <Typography variant="h2" mb={4}>
        Select the record type you&apos;d like to import
      </Typography>
      <Box sx={{ width: '440px', mt: 2 }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Typography color="neutral.n400" align="center" mt={2}>
        Data migration might take a few minutes. Please wait...
      </Typography>
    </>
  );

  const renderMappingContent = () => (
    <>
      <Typography variant="h2" mt={4} mb={2} textAlign="center">
        Mapping
      </Typography>
      <Typography mb={4} variant="body1" textAlign="center" color="neutral.n400">
        You can manually select the objects that you want to import and they will be mapped automatically, or choose a
        saved schema.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3 }}>
        {renderCard(
          'Object Selection',
          <DescriptionIcon fontSize="large" color="primary" />,
          selectedCard === 'Object Selection',
        )}
        {renderCard('Saved Mapping', <SaveIcon fontSize="large" color="primary" />, selectedCard === 'Saved Mapping')}
      </Box>
    </>
  );

  const renderCard = (title: string, icon: JSX.Element, isSelected: boolean) => (
    <Card
      sx={{
        width: '211px',
        height: '120px',
        textAlign: 'center',
        position: 'relative',
        border: isSelected ? '2px solid blue' : '2px solid transparent',
        cursor: 'pointer',
      }}
      onClick={() => handleCardSelect(title)}
    >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>{icon}</Box>
        <Typography variant="labelMedium14" gutterBottom>
          {title}
        </Typography>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {openModal && (
        <AuthModal open={openModal} onClose={() => setOpenModal(false)} onAuthenticate={handleModalAuthenticate} />
      )}

      {!openModal && isLoading ? renderProgressContent() : renderMappingContent()}
    </Box>
  );
};
