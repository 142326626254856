import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PRIVATE_ABS_ROUTE_PATHS } from 'core/constants';
import { useCallback } from 'react';

type ProgressActionsProps = {
  isComplete: boolean;
  onNewSourceClick: () => void;
};

const ProgressActions: React.FC<ProgressActionsProps> = ({ isComplete, onNewSourceClick }) => {
  const navigate = useNavigate();

  const navigateToGraphSpace = useCallback(() => {
    navigate(PRIVATE_ABS_ROUTE_PATHS.graphSpace);
  }, [navigate]);

  const navigateToGraphSpaceSalesTeamSpace = useCallback(() => {
    navigate(PRIVATE_ABS_ROUTE_PATHS.salesTeamSpace);
  }, [navigate]);

  return (
    <Box gap={2} display="flex" alignContent="center" flexDirection="column">
      {isComplete && (
        <Button variant="contained" onClick={navigateToGraphSpace}>
          View Graph Space
        </Button>
      )}
      {isComplete && (
        <Typography color="primary.gray" align="center">
          Or
        </Typography>
      )}
      <Button
        variant="contained"
        onClick={navigateToGraphSpaceSalesTeamSpace}
        sx={{ backgroundColor: 'neutral.white', border: '1px solid blue', color: 'primary.main' }}
      >
        Integrate New Data Source
      </Button>
    </Box>
  );
};

export default ProgressActions;
