import { shallowEqual, useSelector } from 'react-redux';

import { RootState } from 'store/types';
import { useActionCreator } from 'hooks';

import {
  setError,
  setSuccess,
  updateName,
  updateTemplate,
  resetGraphSpace,
  toggleIsArchived,
  updateDataSource,
  updateObjectStart,
  updateDescription,
  updateGraphSpaceValidations,
} from './actions';

import { GraphSpaceReturnHook } from './types';

export const useGraphSpace = (): GraphSpaceReturnHook => {
  const graphSpaceState = useSelector((state: RootState) => state.graphSpace, shallowEqual);

  return {
    ...graphSpaceState,
    // graphSpaceHome
    updateName: useActionCreator(updateName),
    toggleIsArchived: useActionCreator(toggleIsArchived),
    updateDescription: useActionCreator(updateDescription),
    updateGraphSpaceValidations: useActionCreator(updateGraphSpaceValidations),

    // createNewGraphSpace
    updateTemplate: useActionCreator(updateTemplate),
    resetGraphSpace: useActionCreator(resetGraphSpace),
    updateObjectStart: useActionCreator(updateObjectStart),

    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    updateDataSource: useActionCreator(updateDataSource),
  };
};
