import { Box, CircularProgress, Typography } from '@mui/material';
import theme from 'core/theme';
import { FC } from 'react';

export const SmartRecommendationLoading: FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Box
        flex={10}
        sx={{
          border: `1px dotted ${theme.palette.primary.main}`,
          borderRight: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '5px 0px 0px 5px',
          height: '32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="p16" sx={{ color: theme.palette.neutral.darkGray }}>
          Searching recommendation...
        </Typography>
      </Box>
      <Box
        flex={1}
        sx={{
          backgroundColor: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '0px 5px 5px 0px',
          height: '32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress sx={{ color: theme.palette.neutral.white }} size={13} />
      </Box>
    </Box>
  );
};
