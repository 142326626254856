import React, { FC, useState } from 'react';

import { isUndefined } from 'lodash';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Step,
  Grid,
  Button,
  styled,
  Stepper,
  StepLabel,
  Typography,
  StepConnector,
  stepConnectorClasses,
} from '@mui/material';

interface Step {
  label: string;
  validate: () => boolean;
  button?: string;
}

interface CustomStepperProps {
  steps: Step[];
  stepContent: JSX.Element[];
  extraData?: { step: string; extraMessage?: React.ReactNode; extraButton?: React.ReactNode };
  isHiddenButtons?: { step: string; isHidden?: boolean };
  finalRoute: string;
}

interface SquareStepIconProps {
  active?: boolean;
  completed?: boolean;
  icon: React.ReactNode;
}

const CustomStepConnector: FC<any> = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[400],
    borderWidth: '4px',
  },
}));

const SquareStepIcon: FC<SquareStepIconProps> = (props) => {
  const { active, completed, icon } = props;

  return (
    <Box
      sx={{
        width: 24,
        height: 24,
        color: 'white',
        display: 'flex',
        fontWeight: 'bold',
        borderRadius: '4px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: completed ? 'primary.main' : active ? 'primary.light' : 'grey.300',
      }}
    >
      <Typography variant="body2">{icon}</Typography>
    </Box>
  );
};

const CustomStepper: FC<CustomStepperProps> = ({ steps, extraData, finalRoute, stepContent, isHiddenButtons }) => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);

  const currentStep = steps[activeStep];

  const handleNext = () => {
    const isLastStep = steps.length - 1 == activeStep;

    if (currentStep.validate()) {
      if (isLastStep) {
        navigate(finalRoute);
      } else {
        setActiveStep((prevStep) => prevStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
      }}
    >
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<CustomStepConnector />}
        sx={{
          width: '100%',
          padding: '16px 0',
          justifyContent: 'space-between',
        }}
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={(props: any) => (
                <SquareStepIcon active={props.active} completed={props.completed} icon={index + 1} />
              )}
            >
              <Typography color="neutral.n400">{step.label}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box sx={{ overflowY: 'auto', padding: 2 }}>{stepContent[activeStep]}</Box>

      {isHiddenButtons?.step === steps[activeStep].label ||
        (isHiddenButtons?.isHidden !== true && (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{
              bottom: 0,
              zIndex: 10,
              position: 'fixed',
              padding: '24px 32px',
              backgroundColor: 'neutral.white',
            }}
          >
            <Button variant="outlined" onClick={handleBack} disabled={activeStep === 0}>
              Back
            </Button>

            <Grid item>
              <Grid sx={{ display: 'flex', gap: 2 }}>
                {extraData?.step === steps[activeStep].label && extraData?.extraMessage && extraData.extraMessage}
                <Button variant="text">Cancel</Button>
                {extraData?.step === steps[activeStep].label && extraData?.extraButton && extraData.extraButton}
                <Button
                  variant="contained"
                  onClick={handleNext}
                  // disabled={!steps[activeStep].validate()}
                >
                  {isUndefined(steps[activeStep].button) ? 'Next' : steps[activeStep].button}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Box>
  );
};

export default CustomStepper;
