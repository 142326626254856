import { FC } from 'react';
import { Typography, Grid, Box, CircularProgress, Backdrop } from '@mui/material';
import { SearchDropdown } from './components/SearchDropdown';
import { Container, SvgButton } from './ui';
import { GraphRender } from './components/GraphRender';
import { GraphRenderViewSelector } from './components/GraphRenderViewSelector';
import { ReactComponent as QuestionIcon } from 'assets/icons/questionOfRing.svg';

//hook
import { ReactFlowProvider } from 'reactflow';
import { useGraphRender } from 'store/graphRender/hooks';
import { FilterBar } from './components/FilterBar';
import { FilterPanel } from './components/FilterPanel';
import { GraphFooterCounter } from './components/GraphFooterCounter';
import { LeadViewSortButton } from './components/LeadViewSortButton';
import { Show } from '../../components/show';
import { GraphRenderView } from 'store/graphRender/types';

const viewsWithFilters = [GraphRenderView.ACCOUNT, GraphRenderView.DUPLICATES];

const DataGraph: FC = () => {
  const { loading: graphRenderLoading } = useGraphRender();
  const {
    data: { graphObjectCounter, view },
  } = useGraphRender();

  return (
    <Container flexDirection={'column'}>
      <Grid
        container
        spacing={1}
        sx={{ backgroundColor: 'neutral.lightGray', padding: '24px 32px 16px', position: 'relative', zIndex: 3 }}
      >
        <Grid item xs={12} sm={5} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h2" sx={{ color: 'neutral.main' }}>
            My Accounts Universe
          </Typography>
        </Grid>

        <Grid item xs={12} sm={7} display="flex" justifyContent="right" sx={{ alignItems: 'center' }} gap={1}>
          <Grid item xs={8} display={'flex'} justifyContent={'right'} gap={1}>
            <SvgButton sx={{ justifyContent: 'right' }}>
              <QuestionIcon />
            </SvgButton>
            <Box width="100%" sx={{ ml: 'auto' }}>
              <SearchDropdown id="search-contact" placeholder="Search for a contact, lead or opportunity" />
            </Box>
          </Grid>
          <Show when={viewsWithFilters.includes(view)}>
            <Grid item sx={{ width: 'fit-content' }} display={'flex'} justifyContent={'right'}>
              <FilterPanel />
            </Grid>
          </Show>
          <Grid item sx={{ width: 'fit-content' }} display={'flex'} justifyContent={'right'}>
            <LeadViewSortButton />
          </Grid>
          <Grid item sx={{ width: 'fit-content' }} display={'flex'} justifyContent={'right'}>
            <GraphRenderViewSelector />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <FilterBar />
      </Grid>

      <Grid container flex={'1 1 auto'} height={'100%'}>
        <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={graphRenderLoading as boolean}>
          <Grid
            container
            flexDirection={'column'}
            gap={3}
            sx={{ alignContent: 'center', justifyContent: 'center', textAlign: 'center' }}
          >
            <Grid item>
              <CircularProgress color="inherit" />
            </Grid>
            <Grid item>
              <Typography variant="h3" sx={{ color: 'white' }}>
                <strong>{'Loading...'}</strong>
              </Typography>
            </Grid>
          </Grid>
        </Backdrop>
        <Box width={'100%'}>
          <ReactFlowProvider>
            <GraphRender />
          </ReactFlowProvider>
        </Box>
      </Grid>
      <Grid sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <GraphFooterCounter viewName={view} graphObjectCounter={graphObjectCounter} />
      </Grid>
    </Container>
  );
};

export default DataGraph;
