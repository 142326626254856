import { createAction } from '@reduxjs/toolkit';

import { DataSource, GraphSpaceValidations } from './types';

const SET_ERROR = 'graphSpace/SET_ERROR';
const SET_SUCCESS = 'graphSpace/SET_SUCCESS';
const SET_LOADING = 'graphSpace/SET_LOADING';

const UPDATE_NAME = 'graphSpace/UPDATE_NAME';
const TOGGLE_IS_ARCHIVED = 'graphSpace/TOGGLE_IS_ARCHIVED';
const UPDATE_DESCRIPTION = 'graphSpace/UPDATE_DESCRIPTION';

const UPDATE_TEMPLATE = 'graphSpace/UPDATE_TEMPLATE';
const UPDATE_OBJECT_START = 'graphSpace/UPDATE_OBJECT_START';
const UPDATE_DATASOURCE = 'graphSpace/UPDATE_DATASOURCE';
const INIT_STATES = 'graphSpace/INIT_STATES';

export const setError = createAction<string | boolean>(SET_ERROR);
export const setSuccess = createAction<string | boolean>(SET_SUCCESS);
export const setLoading = createAction<string | boolean>(SET_LOADING);

// graphSpaceHome
export const toggleIsArchived = createAction<number>(TOGGLE_IS_ARCHIVED);
export const updateName = createAction<{ graphSpaceId?: number; name: string }>(UPDATE_NAME);
export const updateDescription = createAction<{ graphSpaceId?: number; description: string }>(UPDATE_DESCRIPTION);

// addGraphSpace
const RESET_GRAPH_SPACE = 'graphSpace/RESET_GRAPH_SPACE';
export const resetGraphSpace = createAction(RESET_GRAPH_SPACE);

const UPDATE_GRAPH_SPACE_VALIDATIONS = 'graphSpace/UPDATE_GRAPH_SPACE_VALIDATIONS';
export const updateGraphSpaceValidations = createAction<GraphSpaceValidations>(UPDATE_GRAPH_SPACE_VALIDATIONS);

export const updateTemplate = createAction<{ graphSpaceId?: number; template: string }>(UPDATE_TEMPLATE);
export const updateObjectStart = createAction<{ graphSpaceId?: number; objectStart: string }>(UPDATE_OBJECT_START);

export const updateDataSource = createAction<{ graphSpaceId: number; dataSource: DataSource }>(UPDATE_DATASOURCE);
export const initStates = createAction(INIT_STATES);
