import { useActionCreator } from 'hooks';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'store/types';
import {
  setSuccess,
  setError,
  setLoading,
  getMigrationTableRecord,
  setRenderEdges,
  setRenderNodes,
  updateEdges,
  updateNodes,
  cleanState,
  setSearchFilterOption,
  getFuzzySearch,
  cleanFilterData,
  setSearchTerm,
  setEdgesToExpand,
  setEdgesHashMap,
  setNodesHashMap,
  addToEdgesHashMap,
  addToNodesHashMap,
  setCustomDesignMap,
  setSelectedNode,
  setAccountHoverId,
  setSelectedNodeFromSearchBar,
  setGraphView,
  setShowDuplicationSnackBar,
  setShowNeptuneStatusModal,
  setDeduplicationPreloadData,
  setDuplicatedNodeIds,
  setDefaultDesign,
  setSchemaName,
  setMasterRecordIds,
  setHoverNodeId,
  setDuplicationCounterMap,
  setRenderDuplicatedEdgesOnHover,
  setRenderDuplicatedNodesOnHover,
  setGraphSearchResult,
  setGraphAccountViewResult,
  setGraphOpenLeadResult,
  setOpenMergeModal,
  setRecordsToMerge,
  setShowRecordPanel,
  setShowSearchDetail,
  setGraphFilter,
  partialUpdateGraphFilter,
  setQuickGraphFilter,
  partialUpdateQuickGraphFilter,
  setGraphCustomFilter,
  partialUpdateCustomGraphFilter,
  setGraphObjectCounter,
  setShowCreateActivityModal,
  setSelectedAccountPanelView,
  setGraphOpenLeadResultCounter,
  setLeadViewInitialGroupData,
  setLeadViewGroupByFields,
  setLeadViewGroupByFieldsSelected,
  setRenderComponents,
  setLeadViewLoadedBridgeNode,
  setLeadViewSortPanelShow,
  setLeadViewDateFields,
  setLeadViewSortByField,
  setWhiteEdgeBlockedNodeId,
  setLeadViewSetExpandSearchNodes,
  clearLeadViewLoadedBridgeNode,
  duplicateQuickFilterSetSelectedTables,
} from './actions';
import { GraphRendererReturnHook } from './types';

export const useGraphRender = (): GraphRendererReturnHook => {
  const GraphRenderState = useSelector((state: RootState) => state.graphRender, shallowEqual);

  return {
    ...GraphRenderState,
    leadViewReducers: {
      setLeadViewInitialGroupData: useActionCreator(setLeadViewInitialGroupData),
      setLeadViewGroupByFields: useActionCreator(setLeadViewGroupByFields),
      setLeadViewGroupByFieldsSelected: useActionCreator(setLeadViewGroupByFieldsSelected),
      setLeadViewLoadedBridgeNode: useActionCreator(setLeadViewLoadedBridgeNode),
      clearLeadViewLoadedBridgeNode: useActionCreator(clearLeadViewLoadedBridgeNode),
      setLeadViewSortPanelShow: useActionCreator(setLeadViewSortPanelShow),
      setLeadViewDateFields: useActionCreator(setLeadViewDateFields),
      setLeadViewSortByField: useActionCreator(setLeadViewSortByField),
      setLeadViewSetExpandSearchNodes: useActionCreator(setLeadViewSetExpandSearchNodes),
    },
    duplicateQuickFilterReducers: {
      duplicateQuickFilterSetSelectedTables: useActionCreator(duplicateQuickFilterSetSelectedTables),
    },
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    setLoading: useActionCreator(setLoading),
    setOpenMergeModal: useActionCreator(setOpenMergeModal),
    setShowRecordPanel: useActionCreator(setShowRecordPanel),
    setShowSearchDetail: useActionCreator(setShowSearchDetail),
    setRenderDuplicatedNodesOnHover: useActionCreator(setRenderDuplicatedNodesOnHover),
    setRenderDuplicatedEdgesOnHover: useActionCreator(setRenderDuplicatedEdgesOnHover),
    setHoverNodeId: useActionCreator(setHoverNodeId),
    setDuplicationCounterMap: useActionCreator(setDuplicationCounterMap),
    setMasterRecordIds: useActionCreator(setMasterRecordIds),
    setSchemaName: useActionCreator(setSchemaName),
    setDefaultDesign: useActionCreator(setDefaultDesign),
    setDuplicatedNodeIds: useActionCreator(setDuplicatedNodeIds),
    setShowDuplicationSnackBar: useActionCreator(setShowDuplicationSnackBar),
    setShowNeptuneStatusModal: useActionCreator(setShowNeptuneStatusModal),
    setGraphView: useActionCreator(setGraphView),
    setRenderNodes: useActionCreator(setRenderNodes),
    setRenderEdges: useActionCreator(setRenderEdges),
    setRenderComponents: useActionCreator(setRenderComponents),
    setEdgesHashMap: useActionCreator(setEdgesHashMap),
    setNodesHashMap: useActionCreator(setNodesHashMap),
    addToEdgesHashMap: useActionCreator(addToEdgesHashMap),
    addToNodesHashMap: useActionCreator(addToNodesHashMap),
    getMigrationTableRecord: useActionCreator(getMigrationTableRecord),
    updateEdges: useActionCreator(updateEdges),
    updateNodes: useActionCreator(updateNodes),
    cleanState: useActionCreator(cleanState),
    setSearchFilterOption: useActionCreator(setSearchFilterOption),
    getFuzzySearch: useActionCreator(getFuzzySearch),
    cleanFilterData: useActionCreator(cleanFilterData),
    setSearchTerm: useActionCreator(setSearchTerm),
    setEdgesToExpand: useActionCreator(setEdgesToExpand),
    setCustomDesignMap: useActionCreator(setCustomDesignMap),
    setSelectedNode: useActionCreator(setSelectedNode),
    setWhiteEdgeBlockedNodeId: useActionCreator(setWhiteEdgeBlockedNodeId),
    setAccountHoverId: useActionCreator(setAccountHoverId),
    setSelectedNodeFromSearchBar: useActionCreator(setSelectedNodeFromSearchBar),
    setDeduplicationPreloadData: useActionCreator(setDeduplicationPreloadData),
    setGraphSearchResult: useActionCreator(setGraphSearchResult),
    setGraphAccountViewResult: useActionCreator(setGraphAccountViewResult),
    setGraphOpenLeadResult: useActionCreator(setGraphOpenLeadResult),
    setRecordsToMerge: useActionCreator(setRecordsToMerge),
    setGraphFilter: useActionCreator(setGraphFilter),
    partialUpdateGraphFilter: useActionCreator(partialUpdateGraphFilter),
    setQuickGraphFilter: useActionCreator(setQuickGraphFilter),
    partialUpdateQuickGraphFilter: useActionCreator(partialUpdateQuickGraphFilter),
    setGraphCustomFilter: useActionCreator(setGraphCustomFilter),
    partialUpdateCustomGraphFilter: useActionCreator(partialUpdateCustomGraphFilter),
    setGraphObjectCounter: useActionCreator(setGraphObjectCounter),
    setShowCreateActivityModal: useActionCreator(setShowCreateActivityModal),
    setSelectedAccountPanelView: useActionCreator(setSelectedAccountPanelView),
    setGraphOpenLeadResultCounter: useActionCreator(setGraphOpenLeadResultCounter),
  };
};
