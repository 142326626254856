import { Box, Divider, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { ACCOUNT_ID_FIELDS, ACCOUNT_DATE_FIELDS } from '../../../constants';
import { formatDate } from '../../../../../utils/DateUtils';

interface DataMenuViewProps {
  node: any;
  referencedUsers: any;
}

const DataMenuView: FC<DataMenuViewProps> = (props: DataMenuViewProps) => {
  const { node, referencedUsers } = props;

  return (
    <>
      {Object.keys(node || {}).map((key, index) => {
        if (node[key] === '[object Object]') {
          return null;
        }
        let value = node[key];

        if (ACCOUNT_ID_FIELDS.includes(key) && referencedUsers && referencedUsers[value]?.Name) {
          value = referencedUsers[value].Name;
        }

        if (ACCOUNT_DATE_FIELDS.includes(key)) {
          value = formatDate(value);
        }

        return (
          <Box key={`${node.id}-${key}-${index}`}>
            <Grid item>
              <Typography variant="labelRegular12" sx={{ color: 'neutral.n400' }}>
                {key}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="p14">{value}</Typography>
            </Grid>

            <Divider />
          </Box>
        );
      })}
    </>
  );
};

export default DataMenuView;
