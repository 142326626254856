import { Box, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Handle, Position, NodeProps, useReactFlow } from 'reactflow';
import { ReactComponent as OpportunitiesIcon } from 'assets/icons/graph/graphOpportunityIcon.svg';
import { ReactComponent as LeadsIcon } from 'assets/icons/graph/graphLeadIcon.svg';
import { ReactComponent as ContactsIcon } from 'assets/icons/graph/graphContactIcon.svg';
import { ReactComponent as CaseIcon } from 'assets/icons/graph/graphCaseIcon.svg';
import { ReactComponent as CollapseIcon } from 'assets/icons/graph/graphCollapsedIcon.svg';
import { ReactComponent as UnCollapseIcon } from 'assets/icons/graph/graphUncollapsedIcon.svg';
import { ReactComponent as GraphCircleIcon } from 'assets/icons/graph/graphCircle.svg';
import theme from 'core/theme';
import { ComponentDesign } from '../../types';
import { useGraphRender } from 'store/graphRender/hooks';
import { useGraphRenderLayoutUtils } from '../../hooks/utils/useGraphRenderLayoutUtils';
import _ from 'lodash';

export const BridgeNode: FC<NodeProps> = (props: NodeProps) => {
  const { onHide } = useGraphRenderLayoutUtils();
  const reactFlow = useReactFlow();
  const { data, id, selected, dragging } = props;
  const { bridgeLabel, _collapsed, connectionsCount } = data;
  const collapsed = _collapsed == null ? true : _collapsed;
  const HandlerStyle = { display: 'none' };

  const {
    data: { customDesignMap, defaultDesign },
    setSelectedNode,
    setSelectedNodeFromSearchBar,
  } = useGraphRender();

  let design = defaultDesign ? defaultDesign : ComponentDesign.STANDARD;

  if (customDesignMap[id]) {
    design = customDesignMap[id];
  }

  useEffect(() => {
    if (selected) {
      const node = reactFlow.getNode(id);
      const nodeTmp = _.cloneDeep(node);
      setSelectedNode(nodeTmp);
      setSelectedNodeFromSearchBar(nodeTmp);
    }
  }, [dragging, id, reactFlow, selected, setSelectedNode, setSelectedNodeFromSearchBar]);

  const onClickHandler = useCallback(() => {
    onHide(id, !collapsed, { recursionLevel: 0 });
  }, [collapsed, id, onHide]);

  const borderColor = useMemo(() => {
    if (design === ComponentDesign.SEARCHING || design === ComponentDesign.DUPLICATE_INACTIVE) {
      return theme.palette.neutral.subtone3;
    }
    return theme.palette.primary.darkBlueHigh;
  }, [design]);

  const shouldRenderBridgeLabel = useMemo(() => {
    if (design === ComponentDesign.DUPLICATE_INACTIVE) {
      return false;
    }
    return true;
  }, [design]);

  const shouldRenderIcon = useMemo(() => {
    if (design === ComponentDesign.SEARCHING || design === ComponentDesign.DUPLICATE_INACTIVE) {
      return false;
    }
    return true;
  }, [design]);

  const shouldRenderConnectionCount = useMemo(() => {
    if (
      design === ComponentDesign.SEARCHING ||
      design === ComponentDesign.HIGHLIGH ||
      design === ComponentDesign.DUPLICATE_INACTIVE
    ) {
      return false;
    }
    return true;
  }, [design]);

  const shouldRenderExpandableIcon = useMemo(() => {
    if (
      design === ComponentDesign.SEARCHING ||
      design === ComponentDesign.HIGHLIGH ||
      ComponentDesign.DUPLICATE_INACTIVE
    ) {
      return false;
    }
    return true;
  }, [design]);

  const getIcon = useCallback((bridgeLabel: string) => {
    if (bridgeLabel === 'Opportunity') {
      return <OpportunitiesIcon width={'100%'} height={'100%'} />;
    } else if (bridgeLabel === 'Lead' || bridgeLabel === 'Open Leads') {
      return <LeadsIcon width={'100%'} height={'100%'} />;
    } else if (bridgeLabel === 'Case' || bridgeLabel === 'Event') {
      return <CaseIcon width={'100%'} height={'100%'} />;
    } else {
      return <ContactsIcon width={'100%'} height={'100%'} />;
    }
  }, []);

  const icon = useMemo(() => getIcon(bridgeLabel), [bridgeLabel, getIcon]);

  return (
    <Box
      sx={{
        border: '5px solid',
        borderColor: borderColor,
        backgroundColor: theme.palette.neutral.white,
        borderRadius: '100%',
        width: '100%',
        height: '100%',
      }}
      onClick={onClickHandler}
    >
      {shouldRenderIcon && <Box sx={{ padding: 0, margin: 0, width: '100%', height: '100%' }}>{icon}</Box>}
      {shouldRenderBridgeLabel && (
        <Box
          position={'absolute'}
          top={'-45%'}
          left={'-50%'}
          width={'200%'}
          sx={{ color: theme.palette.primary.gray, textAlign: 'center' }}
        >
          <Typography variant="labelRegular12">{bridgeLabel}</Typography>
        </Box>
      )}
      {shouldRenderExpandableIcon && (
        <Box position={'absolute'} bottom={'-20%'} left={'-2%'} sx={{ color: theme.palette.primary.gray }}>
          {collapsed === true ? (
            <CollapseIcon width={'10px'} height={'10px'} />
          ) : (
            <UnCollapseIcon width={'10px'} height={'10px'} />
          )}
        </Box>
      )}
      {collapsed && shouldRenderConnectionCount && (
        <>
          <GraphCircleIcon
            style={{ position: 'absolute', top: '-10%', right: '-10%' }}
            width={'25px'}
            height={'25px'}
          />
          <Box
            position={'absolute'}
            top={'-10%'}
            right={'-10%'}
            width={'25px'}
            height={'25px'}
            sx={{
              color: theme.palette.primary.darkBlueHigh,
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="labelRegular12" sx={{ fontWeight: 'bold' }}>
              {connectionsCount}
            </Typography>
          </Box>
        </>
      )}
      <Handle position={Position.Right} type="source" style={HandlerStyle} />
      <Handle position={Position.Left} type="target" style={HandlerStyle} />
    </Box>
  );
};
