import { Box, Divider, Grid, Typography } from '@mui/material';
import { ReactComponent as LikeIcon } from 'assets/icons/like.svg';
import { ReactComponent as DisLikeIcon } from 'assets/icons/disLike.svg';
import { updateAIRecommendationFeedBack } from 'http/ai';
import theme from 'core/theme';
import { FC, useCallback, useMemo, useState } from 'react';

interface RecommendationFeedBackProps {
  recommendationFeedBack?: boolean;
  recommendationId: string;
  migrationId: string;
}

export const RecommendationFeedBack: FC<RecommendationFeedBackProps> = (props) => {
  const { recommendationFeedBack, migrationId, recommendationId } = props;
  const [feedBackWritten, setFeedBackWritten] = useState<boolean>();

  const selectedStyle = { fill: theme.palette.primary.main };

  const calculatedFeedBack = useMemo(
    () => feedBackWritten ?? recommendationFeedBack,
    [feedBackWritten, recommendationFeedBack],
  );

  const handleUpdateAiFeedBack = useCallback(
    (feedback: boolean) => {
      if (feedback !== calculatedFeedBack) {
        setFeedBackWritten(feedback);
        updateAIRecommendationFeedBack(migrationId, recommendationId, feedback);
      }
    },
    [calculatedFeedBack, migrationId, recommendationId],
  );

  return (
    <Grid container sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center' }}>
      <Grid item>
        <Typography sx={{ color: theme.palette.neutral.n400, lineHeight: '16px', fontSize: '12px' }}>
          Was this recommendation helpful?
        </Typography>
      </Grid>
      <Grid item>
        <Box
          onClick={() => handleUpdateAiFeedBack(true)}
          sx={{ display: 'flex', alignItems: 'center', ':hover': { cursor: 'pointer' } }}
        >
          <LikeIcon style={calculatedFeedBack === true ? selectedStyle : {}} />
          <Typography variant="labelRegular12" color={theme.palette.primary.main}>
            Yes
          </Typography>
        </Box>
      </Grid>
      <Divider orientation="vertical" />
      <Grid item>
        <Box
          onClick={() => handleUpdateAiFeedBack(false)}
          sx={{ display: 'flex', alignItems: 'center', ':hover': { cursor: 'pointer' } }}
        >
          <DisLikeIcon style={calculatedFeedBack === false ? selectedStyle : {}} />
          <Typography variant="labelRegular12" color={theme.palette.primary.main}>
            No
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
