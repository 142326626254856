import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { createGenericSlice } from '../../firebase-redux/generic';
import {
  getMigrationTableRecord,
  setError,
  setLoading,
  setSuccess,
  setRenderNodes,
  setRenderEdges,
  updateEdges,
  updateNodes,
  setSearchFilterOption,
  getFuzzySearch,
  cleanFilterData,
  setSearchTerm,
  setEdgesToExpand,
  setEdgesHashMap,
  setNodesHashMap,
  setCustomDesignMap,
  setSelectedNode,
  setAccountHoverId,
  setSelectedNodeFromSearchBar,
  setGraphView,
  setShowDuplicationSnackBar,
  setShowNeptuneStatusModal,
  setDeduplicationPreloadData,
  setDuplicatedNodeIds,
  setDefaultDesign,
  setSchemaName,
  setMasterRecordIds,
  setHoverNodeId,
  setDuplicationCounterMap,
  setRenderDuplicatedEdgesOnHover,
  setRenderDuplicatedNodesOnHover,
  setGraphSearchResult,
  setGraphAccountViewResult,
  setGraphOpenLeadResult,
  setOpenMergeModal,
  setRecordsToMerge,
  setShowRecordPanel,
  setShowSearchDetail,
  setGraphFilter,
  partialUpdateGraphFilter,
  setQuickGraphFilter,
  partialUpdateQuickGraphFilter,
  setGraphCustomFilter,
  partialUpdateCustomGraphFilter,
  setGraphObjectCounter,
  setShowCreateActivityModal,
  setSelectedAccountPanelView,
  setGraphOpenLeadResultCounter,
  setLeadViewInitialGroupData,
  setLeadViewGroupByFields,
  setLeadViewGroupByFieldsSelected,
  setRenderComponents,
  setLeadViewLoadedBridgeNode,
  setLeadViewSortPanelShow,
  setLeadViewDateFields,
  setLeadViewSortByField,
  setWhiteEdgeBlockedNodeId,
  setLeadViewSetExpandSearchNodes,
  addToEdgesHashMap,
  addToNodesHashMap,
  clearLeadViewLoadedBridgeNode,
  duplicateQuickFilterSetSelectedTables,
} from './actions';
import {
  AccountPanelMenuViews,
  DuplicateTablesQuickFilterOptions,
  GraphRenderView,
  GraphRendererState,
  SearchFilterData,
} from './types';
import _ from 'lodash';
import { ComponentDesign } from 'pages/DataGraph/components/GraphRender/types';
import { resetApp } from 'store/actions';

export const initialDuplicateQuickFilter = {
  selectedTables: [
    DuplicateTablesQuickFilterOptions.Account,
    DuplicateTablesQuickFilterOptions.Opportunity,
    DuplicateTablesQuickFilterOptions.Contact,
    DuplicateTablesQuickFilterOptions.Lead,
  ],
};

const initialSearchFilterData: SearchFilterData[] = [
  { active: true, label: 'Accounts', tableName: 'Account', searchField: 'Name' },
  {
    active: true,
    label: 'Contacts',
    tableName: 'Contact',
    searchField: 'Name',
    fallBackSearchField: ['FirstName', 'LastName'],
  },
  {
    active: true,
    label: 'Leads',
    tableName: 'Lead',
    searchField: 'Name',
    fallBackSearchField: ['FirstName', 'LastName'],
  },
  { active: true, label: 'Opportunity', tableName: 'Opportunity', searchField: 'Name', fallBackSearchField: [] },
];

export const initialState: GraphRendererState = {
  error: false,
  loading: true,
  success: false,
  data: {
    duplicateQuickFilter: initialDuplicateQuickFilter,
    view: GraphRenderView.ACCOUNT,
    tableRecords: {},
    renderNodes: {},
    renderEdges: {},
    nodeHashMap: {},
    edgesHashMap: {},
    searchFilterOption: _.cloneDeep(initialSearchFilterData),
    searchFilterResult: {},
    searchTerm: '',
    searching: false,
    edgesToExpand: [],
    customDesignMap: {},
    whiteEdgeBlockNodeId: '',
    selectedNode: undefined,
    selectedNodeFromSearchBar: undefined,
    hoverAccountId: '',
    hoverNodeId: '',
    showDuplicationSnackBar: false,
    showNeptuneStatusModal: false,
    duplicatedNodeIds: [],
    defaultDesign: ComponentDesign.STANDARD,
    masterRecordIds: [],
    duplicationCounterMap: {},
    renderDuplicateEdgesOnHover: [],
    renderDuplicateNodesOnHover: [],
    openMergeModal: false,
    showSearchDetail: false,
    graphFilter: {},
    quickFilterTmp: {},
    customFilterTmp: {},
    graphObjectCounter: {},
    showCreateActivityModal: false,
    AccountPanelView: {
      menu: AccountPanelMenuViews.Data,
      open: false,
    },
    leadViewState: {
      groupByFieldsSelected: [],
      maxNodeCountToExpand: 20,
      loadedBridgeNodes: {},
      dateFields: [],
      sortByField: '',
      expandSearchNodes: {},
      sortPanelState: {
        open: false,
      },
    },
  },
};

const slice = createGenericSlice({
  name: 'graph-render',
  initialState: _.cloneDeep(initialState),
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<GraphRendererState>): void => {
    builder.addCase(duplicateQuickFilterSetSelectedTables, (state, { payload }) => {
      state.data.duplicateQuickFilter.selectedTables = payload;
    });
    builder.addCase(clearLeadViewLoadedBridgeNode, (state) => {
      state.data.leadViewState.loadedBridgeNodes = {};
    });
    builder.addCase(setLeadViewSetExpandSearchNodes, (state, { payload }) => {
      state.data.leadViewState.expandSearchNodes = payload;
    });
    builder.addCase(setWhiteEdgeBlockedNodeId, (state, { payload }) => {
      state.data.whiteEdgeBlockNodeId = payload;
    });
    builder.addCase(setLeadViewDateFields, (state, { payload }) => {
      state.data.leadViewState.dateFields = payload;
    });
    builder.addCase(setLeadViewSortByField, (state, { payload }) => {
      state.data.leadViewState.sortByField = payload;
    });
    builder.addCase(setLeadViewSortPanelShow, (state, { payload }) => {
      state.data.leadViewState.sortPanelState.open = payload;
    });
    builder.addCase(setLeadViewGroupByFieldsSelected, (state, { payload }) => {
      state.data.leadViewState.groupByFieldsSelected = payload;
    });
    builder.addCase(setLeadViewLoadedBridgeNode, (state, { payload }) => {
      state.data.leadViewState.loadedBridgeNodes[payload.key] = payload.value;
    });
    builder.addCase(setLeadViewGroupByFields, (state, { payload }) => {
      state.data.leadViewState.groupByFields = payload;
    });
    builder.addCase(setLeadViewInitialGroupData, (state, { payload }) => {
      state.data.leadViewState.initialGroupData = payload;
    });
    builder.addCase(setSelectedAccountPanelView, (state, { payload }) => {
      state.data.AccountPanelView.menu = payload;
    });
    builder.addCase(setGraphObjectCounter, (state, { payload }) => {
      state.data.graphObjectCounter = payload;
    });
    builder.addCase(setGraphFilter, (state, { payload }) => {
      state.data.graphFilter = payload;
    });
    builder.addCase(setGraphOpenLeadResultCounter, (state, { payload }) => {
      state.data.graphOpenLeadResultCounter = payload;
    });
    builder.addCase(partialUpdateGraphFilter, (state, { payload }) => {
      _.set(state.data.graphFilter, payload.path, payload.value);
    });
    builder.addCase(setQuickGraphFilter, (state, { payload }) => {
      state.data.quickFilterTmp = payload;
    });
    builder.addCase(partialUpdateQuickGraphFilter, (state, { payload }) => {
      _.set(state.data.quickFilterTmp, payload.path, payload.value);
    });
    builder.addCase(setGraphCustomFilter, (state, { payload }) => {
      state.data.customFilterTmp = payload;
    });
    builder.addCase(partialUpdateCustomGraphFilter, (state, { payload }) => {
      _.set(state.data.customFilterTmp, payload.path, payload.value);
    });
    builder.addCase(setShowSearchDetail, (state, { payload }) => {
      state.data.showSearchDetail = payload;
    });
    builder.addCase(setShowRecordPanel, (state, { payload }) => {
      state.data.AccountPanelView.open = payload;
    });
    builder.addCase(setRecordsToMerge, (state, { payload }) => {
      state.data.recordsToMerge = payload;
    });
    builder.addCase(setOpenMergeModal, (state, { payload }) => {
      state.data.openMergeModal = payload;
    });
    builder.addCase(setShowCreateActivityModal, (state, { payload }) => {
      state.data.showCreateActivityModal = payload;
    });
    builder.addCase(setGraphOpenLeadResult, (state, { payload }) => {
      state.data.graphOpenLeadResult = payload;
    });
    builder.addCase(setGraphAccountViewResult, (state, { payload }) => {
      state.data.graphAccountViewResult = payload;
    });
    builder.addCase(setGraphSearchResult, (state, { payload }) => {
      state.data.graphSearchResult = payload;
    });
    builder.addCase(setRenderDuplicatedNodesOnHover, (state, { payload }) => {
      state.data.renderDuplicateNodesOnHover = payload;
    });
    builder.addCase(setRenderDuplicatedEdgesOnHover, (state, { payload }) => {
      state.data.renderDuplicateEdgesOnHover = payload;
    });
    builder.addCase(setDuplicationCounterMap, (state, { payload }) => {
      state.data.duplicationCounterMap = payload;
    });
    builder.addCase(setHoverNodeId, (state, { payload }) => {
      if (state.data.hoverNodeId !== payload) {
        state.data.hoverNodeId = payload;
      }
    });
    builder.addCase(setMasterRecordIds, (state, { payload }) => {
      state.data.masterRecordIds = payload;
    });
    builder.addCase(setSchemaName, (state, { payload }) => {
      state.data.schemaName = payload;
    });
    builder.addCase(setDefaultDesign, (state, { payload }) => {
      if (state.data.defaultDesign != payload) {
        state.data.defaultDesign = payload;
      }
    });
    builder.addCase(setDuplicatedNodeIds, (state, { payload }) => {
      state.data.duplicatedNodeIds = payload;
    });
    builder.addCase(setDeduplicationPreloadData, (state, { payload }) => {
      state.data.deduplicationPreloadData = payload;
    });
    builder.addCase(setShowNeptuneStatusModal, (state, { payload }) => {
      state.data.showNeptuneStatusModal = payload;
    });
    builder.addCase(setShowDuplicationSnackBar, (state, { payload }) => {
      state.data.showDuplicationSnackBar = payload;
    });
    builder.addCase(setGraphView, (state, { payload }) => {
      state.data.view = payload;
      state.data.searchTerm = '';
      state.data.searching = false;
    });
    builder.addCase(setAccountHoverId, (state, { payload }) => {
      if (state.data.hoverAccountId !== payload) {
        state.data.hoverAccountId = payload;
      }
    });
    builder.addCase(setSelectedNode, (state, { payload }) => {
      state.data.selectedNode = payload;
    });
    builder.addCase(setSelectedNodeFromSearchBar, (state, { payload }) => {
      state.data.selectedNodeFromSearchBar = payload;
    });
    builder.addCase(setCustomDesignMap, (state, { payload }) => {
      state.data.customDesignMap = payload;
    });
    builder.addCase(setRenderComponents, (state, { payload }) => {
      if (payload.nodes && Object.keys(payload.nodes || {}).length > 0) {
        state.data.renderNodes = payload.nodes;
      }
      if (payload.edges && Object.keys(payload.edges || {}).length > 0) {
        state.data.renderEdges = payload.edges;
      }
    });
    builder.addCase(setRenderEdges, (state, { payload }) => {
      state.data.renderEdges = payload;
    });
    builder.addCase(setRenderNodes, (state, { payload }) => {
      state.data.renderNodes = payload;
    });
    builder.addCase(setEdgesHashMap, (state, { payload }) => {
      state.data.edgesHashMap = payload;
    });
    builder.addCase(setNodesHashMap, (state, { payload }) => {
      state.data.nodeHashMap = payload;
    });
    builder.addCase(addToEdgesHashMap, (state, { payload }) => {
      state.data.edgesHashMap = { ...state.data.edgesHashMap, ...payload };
    });
    builder.addCase(addToNodesHashMap, (state, { payload }) => {
      state.data.nodeHashMap = { ...state.data.nodeHashMap, ...payload };
    });
    builder.addCase(setEdgesToExpand, (state, { payload }) => {
      state.data.edgesToExpand = payload;
    });
    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });
    builder.addCase(setLoading, (state, { payload }) => {
      state.loading = payload;
    });
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(setSearchFilterOption, (state, { payload }) => {
      payload.forEach((searchOptionUpdate) => {
        const option = state.data.searchFilterOption.find(
          (option: SearchFilterData) => option.label === searchOptionUpdate.option,
        );
        if (option) {
          option.active = searchOptionUpdate.value;
          if (searchOptionUpdate.disabled != null) {
            option.disabled = searchOptionUpdate.disabled;
          } else {
            option.disabled = false;
          }
          if (searchOptionUpdate.value === false) {
            state.data.searchFilterResult[option.label] = [];
          }
        }
      });
    });
    builder.addCase(getMigrationTableRecord.pending, (state, { meta }) => {
      if (!state.data.tableRecords[`${meta.arg.migrationId}-${meta.arg.tableId}`]) {
        state.data.tableRecords[`${meta.arg.migrationId}-${meta.arg.tableId}`] = {};
        state.data.tableRecords[`${meta.arg.migrationId}-${meta.arg.tableId}`].data = {};
      }
      state.data.tableRecords[`${meta.arg.migrationId}-${meta.arg.tableId}`].loading = true;
      state.data.tableRecords[`${meta.arg.migrationId}-${meta.arg.tableId}`].success = false;
      state.data.tableRecords[`${meta.arg.migrationId}-${meta.arg.tableId}`].error = false;
    });
    builder.addCase(getMigrationTableRecord.fulfilled, (state, { payload, meta }) => {
      if (state.data.tableRecords[`${meta.arg.migrationId}-${meta.arg.tableId}`]) {
        state.data.tableRecords[`${meta.arg.migrationId}-${meta.arg.tableId}`].loading = false;
        state.data.tableRecords[`${meta.arg.migrationId}-${meta.arg.tableId}`].success = true;
        state.data.tableRecords[`${meta.arg.migrationId}-${meta.arg.tableId}`].error = false;
        payload.forEach((record) => {
          const recordId = record.Id;
          state.data.tableRecords[`${meta.arg.migrationId}-${meta.arg.tableId}`].data[recordId] = record;
        });
      }
    });
    builder.addCase(getMigrationTableRecord.rejected, (state, { meta }) => {
      if (state.data.tableRecords[`${meta.arg.migrationId}-${meta.arg.tableId}`]) {
        state.data.tableRecords[`${meta.arg.migrationId}-${meta.arg.tableId}`].loading = false;
        state.data.tableRecords[`${meta.arg.migrationId}-${meta.arg.tableId}`].success = false;
        state.data.tableRecords[`${meta.arg.migrationId}-${meta.arg.tableId}`].error = true;
      }
    });
    builder.addCase(getFuzzySearch.pending, (state, { meta }) => {
      const args = meta.arg;
      const option = state.data.searchFilterOption.find((option: SearchFilterData) => {
        return option.label === args.label;
      });
      if (option) {
        option.loading = true;
        option.error = '';
      }
    });
    builder.addCase(getFuzzySearch.fulfilled, (state, { payload, meta }) => {
      const args = meta.arg;
      const option = state.data.searchFilterOption.find((option: SearchFilterData) => {
        return option.label === args.label;
      });
      if (option) {
        option.loading = false;
        option.error = '';
        state.data.searchFilterResult[option.label] = payload;
      }
    });
    builder.addCase(getFuzzySearch.rejected, (state, { meta }) => {
      const args = meta.arg;
      const option = state.data.searchFilterOption.find((option: SearchFilterData) => {
        return option.label === args.label;
      });
      if (option) {
        option.loading = false;
        option.error = 'Error getting Fuzzy search';
        state.data.searchFilterResult[option.label] = [];
      }
    });
    builder.addCase(updateNodes, (state, { payload }) => {
      payload.forEach((node) => {
        const id = node.id;
        state.data.renderNodes[id] = { ...state.data.renderNodes[id], ...node };
      });
    });
    builder.addCase(updateEdges, (state, { payload }) => {
      payload.forEach((edge) => {
        const id = edge.id;
        state.data.renderEdges[id] = { ...state.data.renderEdges[id], ...edge };
      });
    });
    builder.addCase(resetApp, (state) => {
      const initialStateTmp = _.cloneDeep(initialState);
      Object.keys(state).forEach((key) => {
        // @ts-ignore
        state[key] = initialStateTmp[key];
      });
    });
    builder.addCase(cleanFilterData, (state) => {
      state.data.searchFilterResult = {};
    });
    builder.addCase(setSearchTerm, (state, { payload }) => {
      state.data.searchTerm = payload;
      console.log('SetSearchingTerm', payload);
      if (payload.length > 0) {
        if (state.data.searching !== true) {
          state.data.searching = true;
        }
      } else {
        if (state.data.searching !== false) {
          state.data.searching = false;
        }
      }
    });
  },
});
export const actions = slice.actions;
export default slice.reducer;
