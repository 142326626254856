import _ from 'lodash';

import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import {
  setError,
  setSuccess,
  updateName,
  updateTemplate,
  resetGraphSpace,
  updateDataSource,
  toggleIsArchived,
  updateObjectStart,
  updateDescription,
  updateGraphSpaceValidations,
} from './actions';

import { data } from './data';
import { DataSource, GraphSpaceStatus, IntegratedGraphSpace, InvitedMember } from './types';

export const initialState: GraphSpaceStatus = {
  error: false,
  success: false,
  data: {
    graphSpaces: data.map((p) => {
      return {
        graphSpaceId: p.id,
        name: p.projectName,
        description: p.description,

        image: p.image,
        isArchived: p.isArchived,
        statusIcon: p.statusIcon,

        dataSources: p.icons.map((i) => {
          return {
            icon: i,
          } as DataSource;
        }),

        createdBy: p.createdBy,
        modifiedBy: p.modifiedBy,
        lastModified: p.lastModified,

        invitedMembers: p.invitedMembers.map((im) => {
          return {
            name: im.name,
            photo: im.photo,
            isOnline: im.isOnline,
          } as InvitedMember;
        }),
      } as IntegratedGraphSpace;
    }),
    graphSpace: {
      graphSpaceId: 0,
      name: '',
      description: '',
      statusIcon: 'good',
      image: '',
      template: '',
      objectStart: '',
      createdBy: '',
      lastModified: '',
      modifiedBy: '',
      isArchived: false,
      dataSources: [],
    },
    validations: {
      graphSpaceSetUp: {
        isTemplateValid: false,
        isObjectStartValid: false,
        isGraphSpaceDetailsValid: false,
      },
    },
  },
};

const graphSpaceReducer = createSlice({
  name: 'graphSpace',
  initialState: _.cloneDeep(initialState),
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<GraphSpaceStatus>): void => {
    builder
      .addCase(setError, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(setSuccess, (state, { payload }) => {
        state.success = payload;
      });

    builder // graphSpaceHome
      .addCase(toggleIsArchived, (state, { payload }) => {
        const graphSpace = state.data.graphSpaces.find((gs) => {
          return gs.graphSpaceId === payload;
        });

        if (graphSpace) {
          graphSpace.isArchived = !graphSpace.isArchived;
        }
      })
      .addCase(updateName, (state, { payload }) => {
        const { graphSpaceId, name } = payload;

        if (!payload.graphSpaceId) {
          state.data.graphSpace.name = name;
        } else {
          const graphSpace = state.data.graphSpaces.find((gs) => {
            return gs.graphSpaceId === graphSpaceId;
          });

          if (graphSpace) {
            graphSpace.name = name;
          }
        }
      })
      .addCase(updateDescription, (state, { payload }) => {
        const { graphSpaceId, description } = payload;

        if (!graphSpaceId) {
          state.data.graphSpace.description = description;
        } else {
          const graphSpace = state.data.graphSpaces.find((gs) => {
            return gs.graphSpaceId === graphSpaceId;
          });

          if (graphSpace) {
            graphSpace.description = description;
          }
        }
      });

    builder // addGraphSpace
      .addCase(resetGraphSpace, (state) => {
        state.data.graphSpace = _.cloneDeep(initialState.data.graphSpace);
      })
      .addCase(updateGraphSpaceValidations, (state, payload) => {
        const validations = payload.payload;
        state.data.validations = validations;
      })
      .addCase(updateTemplate, (state, { payload }) => {
        const { graphSpaceId, template } = payload;

        if (!graphSpaceId) {
          state.data.graphSpace.template = template;
          state.data.graphSpace.objectStart = undefined;

          state.data.validations.graphSpaceSetUp.isTemplateValid = true;
          state.data.validations.graphSpaceSetUp.isObjectStartValid = false;
        } else {
          const graphSpace = state.data.graphSpaces.find((gs) => {
            return gs.graphSpaceId === graphSpaceId;
          });

          if (graphSpace) {
            graphSpace.template = template;
          }
        }
      })
      .addCase(updateObjectStart, (state, { payload }) => {
        const { graphSpaceId, objectStart } = payload;

        if (!graphSpaceId) {
          state.data.graphSpace.objectStart = objectStart;
        } else {
          const graphSpace = state.data.graphSpaces.find((gs) => {
            return gs.graphSpaceId === graphSpaceId;
          });

          if (graphSpace) {
            graphSpace.objectStart = objectStart;
          }
        }

        const template = state.data.graphSpace.template;

        if (!!objectStart && !!template) {
          state.data.validations.graphSpaceSetUp.isObjectStartValid = true;
        }
      });

    builder.addCase(updateDataSource, (state, { payload }) => {
      const { graphSpaceId, dataSource } = payload;
      // @ts-ignore
      const graphSpace = state.data.graphSpaces.find((gs) => gs.graphSpaceId === graphSpaceId);
      if (graphSpace) {
        // @ts-ignore
        const index = graphSpace.dataSources.findIndex((ds) => ds.name === dataSource.name);
        if (index !== -1) {
          graphSpace.dataSources![index] = dataSource;
        } else {
          graphSpace.dataSources!.push(dataSource);
        }
      }
      if (state.data.graphSpace.graphSpaceId === graphSpaceId) {
        // @ts-ignore
        const index = state.data.graphSpace.dataSources.findIndex((ds) => ds.name === dataSource.name);
        if (index !== -1) {
          state.data.graphSpace.dataSources![index] = dataSource;
        } else {
          state.data.graphSpace.dataSources!.push(dataSource);
        }
      }
    });
  },
});

export const actions = graphSpaceReducer.actions;
export default graphSpaceReducer.reducer;
