import { Box, Grid, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { GraphObjectCounter, GraphRenderView } from 'store/graphRender/types';
import { ReactComponent as SeparatorIcon } from 'assets/icons/separator.svg';
import { useGraphRender } from 'store/graphRender/hooks';

export interface GraphFooterCounterProps {
  viewName: string;
  graphObjectCounter: GraphObjectCounter;
}

const TablePluralName: any = {
  account: 'accounts',
  contact: 'contacts',
  opportunity: 'opportunities',
  lead: 'leads',
  event: 'events',
  case: 'cases',
};

const allowedTables = Object.keys(TablePluralName);

const GraphFooterCounter: FC<GraphFooterCounterProps> = (props) => {
  const { graphObjectCounter, viewName } = props;
  const {
    data: { view: graphView, searching },
  } = useGraphRender();

  const shouldRenderEventCount = useMemo(() => {
    if (graphView === GraphRenderView.DUPLICATES) return false;
    if (graphView === GraphRenderView.ACCOUNT && searching === true) return false;
    return true;
  }, [graphView, searching]);

  const counterTabs = useMemo(() => {
    const counterTmp = { ...graphObjectCounter };
    if (shouldRenderEventCount === false) {
      delete counterTmp['Event'];
    }
    const sortedKeys = Object.keys(counterTmp).sort();
    return sortedKeys.map((key, index) => {
      const lowerCaseKey = key.toLocaleLowerCase();
      if (!allowedTables.includes(lowerCaseKey)) return null;
      const quantity = counterTmp[key];
      return (
        <Grid key={key} style={{ display: 'inline-block' }}>
          <SeparatorIcon stroke="#898EA1" strokeWidth={'0.3px'} />
          <Box key={`graph-footer-counter-tab-${index}`} sx={{ display: 'inline-block', marginLeft: '10px' }}>
            <Typography variant="p14" sx={{ color: 'neutral.main', textTransform: 'lowercase' }}>
              {`${counterTmp[key]} ${quantity <= 1 ? key : TablePluralName[lowerCaseKey]}`}
            </Typography>
          </Box>
        </Grid>
      );
    });
  }, [graphObjectCounter, shouldRenderEventCount]);

  return (
    <Grid container display={'flex'} wrap="wrap" sx={{ px: '1rem' }}>
      <Grid item display={'flex'} alignItems={'center'} justifyContent={'start'} minHeight={'40px'} gap={1.5}>
        <Box>
          <Typography variant="p14" sx={{ color: 'neutral.main', fontWeight: 'bold' }}>
            {viewName.replace('_', ' ')}
          </Typography>
        </Box>
        {counterTabs}
      </Grid>
    </Grid>
  );
};

export default GraphFooterCounter;
