import { RuleDto } from './dto/rule.dto';
import { GetRuleDto } from './dto/getRuleDto';
import { RuleWhereArray } from './dto/front-end-rule.dto';

import { CreateDataRaptorRuleDto } from './dto/createRuleDto';
import { UpdateDataRaptorRuleDto } from './dto/updateRuleDto';

export enum RuleStatus {
  REQUESTED = 'requested',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum RuleCategory {
  DuplicateDetection = 'duplicate-detention',
  DataValidation = 'data-validation',
  AnomalyDetection = 'anomaly-detection',
}

enum ValidationDataType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

enum ValidationPatternType {
  REGEX = 'REGEX',
  SERVICE = 'SERVICE',
}

enum ValidationPatternCategory {
  EMAIL_FORMAT = 'EMAIL_FORMAT',
  EMAIL_VALIDITY = 'EMAIL_VALIDITY',
  PHONE_FORMAT = 'PHONE_FORMAT',
  PHONE_VALIDITY = 'PHONE_VALIDITY',
}

enum ValidationPatternValidityService {
  DATA_VALIDATION_SERVICE = 'DATA_VALIDATION_SERVICE',
}

interface Option {
  label: string;
  value: string;
}

export interface valueSchema {
  keyName: string;
  label: string;
  type: string;
  options?: Option[];
}

export interface RuleValidationPatternReturnValue {
  [valueName: string]: valueSchema;
}

export interface RuleValidationPattern {
  validationPatternId: string;
  description: string;
  label: string;
  expression: string;
  example: string;
  dataType: ValidationDataType;
  type: ValidationPatternType;
  category: ValidationPatternCategory;
  returnValueSchema: RuleValidationPatternReturnValue;
  serviceName: ValidationPatternValidityService | null;
}

export interface RuleRisk {
  ruleRiskId: string;
  name: string;
  label: string;
  description: string;
}

export interface RuleType {
  ruleTypeId: string;
  name: string;
  label: string;
  color?: string;
  description: string;
}

export interface RuleDepartment {
  ruleDepartmentId: string;
  name: string;
  label: string;
  description: string;
  color: string;
}

export interface DataRaptorRule {
  ruleId: string;
  name: string;
  table: string;
  rule: RuleDto;
  description: string;
  formattedRule: any;
  previousFormattedRule: any;
  frontEndObject: any;
  type: string;
  risk: string;
  department: string;
  dataMigrationId: string;
  violationScore: number;
  tableDependencies: string[];
  ruleTemplateId: string;
  status: string;
  category: string;
  riskLevel: string;
  statusDate: string;
  active: boolean;
  violatedRowCount: number;
  RiskObject?: RuleRisk;
  TypeObject?: RuleType;
  DepartmentObject?: RuleDepartment;
  createdAt?: string;
  updatedAt?: string;
}

export interface ApiCallState {
  error: boolean | string;
  loading: boolean;
  success: boolean | string;
}

export interface RuleType {
  id: string;
  name: string;
  label: string;
  description: string;
}

export interface RuleRisk {
  id: string;
  name: string;
  label: string;
  description: string;
}

export interface RuleDepartment {
  id: string;
  name: string;
  label: string;
  description: string;
}

export interface DataSource {
  dataSourceId: string;
  name: string;
  integrationId?: number;
  type: string;
}

export enum RuleObjectTemplateRefType {
  table = 'table',
  field = 'field',
}

export interface RuleObjectTemplateRef {
  ruleObjectTemplateRefId: string;
  description: string;
  type: RuleObjectTemplateRefType;
  context: string;
}

export interface RuleTemplate {
  ruleTemplateId: string;
  name: string;
  description: string;
  ruleBody: any;
  context: string;
  objectRefs: string[];
  department: string;
  dataSourceName: string;
  DepartmentObject?: RuleDepartment;
  DataSourceObject?: DataSource;
  ObjectReferences?: RuleObjectTemplateRef[];
  createdAt: string;
  updatedAt: string;
  type: string;
  risk: string;
  table: string;
}

export interface RuleTemporalTable {
  ruleTempTableId: string;
  table: string;
  name: string;
  formattedTableName: string;
  definition: {
    where: RuleWhereArray;
  };
  formattedDefinition: string;
  createdAt: Date;
  updatedAt: Date;
  dataMigrationId: string;
  sampleIds?: string[];
}

export interface DataRaptorRuleState {
  error: boolean | string;
  success: boolean | string;
  loading: boolean;
  deleteRuleByIdError: boolean | string;
  deleteRuleByIdSuccess: boolean | string;
  deleteRuleByIdLoading: boolean;
  updateRuleByIdError: boolean | string;
  updateRuleByIdSuccess: boolean | string;
  updateRuleByIdLoading: boolean;
  getRuleRisksState: ApiCallState;
  getRuleDepartmentsState: ApiCallState;
  getRuleTypesState: ApiCallState;
  getRuleByMigrationAndRuleIdState: ApiCallState;
  getRuleValidationPatternsState: ApiCallState;
  data: {
    createdRule?: DataRaptorRule;
    rulesByMigrationAndTable?: DataRaptorRule[];
    selectedRuleId?: string;
    formMode?: string;
    ruleTypes?: RuleType[];
    ruleDepartments?: RuleDepartment[];
    ruleRisks?: RuleRisk[];
    ruleByMigrationAndRuleId?: GetRuleDto;
    ruleValidationPatterns?: RuleValidationPattern[];
  };
}

export interface createRuleArgs {
  migrationId: string;
  rule: CreateDataRaptorRuleDto;
  onSuccess?: (rule: DataRaptorRule) => void;
  onError?: (error: any) => void;
}

export interface updateRuleArgs {
  ruleId: string;
  data: Partial<UpdateDataRaptorRuleDto>;
  onSuccess?: (rule: DataRaptorRule) => void;
  onError?: (error: any) => void;
}

export interface getRulesByMigrationAndTableArgs {
  migrationId: string;
  table: string;
  onSuccess?: (rule: DataRaptorRule[]) => void;
  onError?: (error: any) => void;
}

export interface getRulesByMigrationAndRuleIdArgs {
  migrationId: string;
  ruleId: string;
  onSuccess?: (rule: GetRuleDto) => void;
  onError?: (error: any) => void;
}

export interface deleteRuleByIdArgs {
  ruleId: string;
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

export interface DataRaptorReturnHook extends DataRaptorRuleState {
  setError: (error: string | boolean) => void;
  setSuccess: (success: string | boolean) => void;
  setLoading: (loading: boolean) => void;
  setSelectedRuleId: (ruleId: string) => void;
  setFormMode: (mode: string) => void;
  createRule: (args: createRuleArgs) => void;
  updateRuleById: (args: updateRuleArgs) => void;
  getRulesByMigrationAndTable: (args: getRulesByMigrationAndTableArgs) => void;
  getRuleByMigrationAndRuleId: (args: getRulesByMigrationAndRuleIdArgs) => void;
  deleteRuleById: (args: deleteRuleByIdArgs) => void;
  getRuleRisks: () => void;
  getRuleDepartments: () => void;
  getRuleTypes: () => void;
  getRuleValidationPatterns: () => void;
}
