import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Divider, Grid, List, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ReactComponent as SearchIcon } from 'assets/icons/searchGray.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/circle-close.svg';
import theme from 'core/theme';
import FilterChip from './components/FilterChip';
import { useGraphRender } from 'store/graphRender/hooks';
import { useMigration } from 'store/migration/hooks';
import ResultListItem from './components/ResultListItem';
import { LinearProgress } from '@mui/material';
import { GraphRenderView } from 'store/graphRender/types';

interface SearchDropdownProps {
  id: string;
  placeholder: string;
}

const useStyles = makeStyles(() => ({
  removeFocusBorder: {
    '& .MuiInput-root::before ': {
      border: 'none',
    },
    '& .MuiInput-root:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '& .MuiInput-root::after ': {
      border: 'none',
    },
    '& .MuiInput-root:focus::before': {
      border: 'none',
    },
    '& .MuiInput-root:focus::after': {
      border: 'none',
    },
  },
}));

const SearchDropdown: FC<SearchDropdownProps> = (props) => {
  const { placeholder } = props;
  const classes = useStyles();
  const searchBoxRef = useRef(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [searchBarWidth, setSearchBarWidth] = useState(100);

  const {
    data: { searchFilterOption, searchTerm, searchFilterResult, showSearchDetail, view: graphView },
    setSearchFilterOption,
    getFuzzySearch,
    cleanFilterData,
    setSearchTerm,
    setLoading,
    setShowSearchDetail,
  } = useGraphRender();
  const {
    data: { migrationId },
  } = useMigration();

  const handleSetShowSearchDetail = useCallback(
    (value: boolean) => {
      if (value === true && searchTerm) {
        setShowSearchDetail(value);
      }
      if (value === false) {
        setShowSearchDetail(false);
      }
    },
    [searchTerm, setShowSearchDetail],
  );

  useEffect(() => {
    let timer: any;
    if (searchTerm && searchTerm.length > 0) {
      handleSetShowSearchDetail(true);
      setLoading(true);
      timer = setTimeout(() => {
        if (searchTerm && searchTerm.length > 0) {
          searchFilterOption
            .filter((option) => option.active === true)
            .forEach((option) => {
              getFuzzySearch({
                migrationId: migrationId,
                tableId: option.tableName,
                label: option.label,
                options: {
                  field: option.searchField,
                  value: searchTerm,
                  fallBackSearchField: option.fallBackSearchField,
                },
              });
            });
        }
      }, 750);
    } else {
      setShowSearchDetail(false);
      cleanFilterData();
      setLoading(false);
    }

    return () => timer && clearTimeout(timer);
  }, [
    cleanFilterData,
    getFuzzySearch,
    handleSetShowSearchDetail,
    migrationId,
    // searchFilterOption,
    searchTerm,
    setLoading,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  useEffect(() => {
    if (searchBoxRef.current) {
      const componentWidth = (searchBoxRef.current as HTMLElement).offsetWidth;
      setSearchBarWidth(componentWidth);
    }
  }, [screenWidth]);

  useEffect(() => {
    if ([GraphRenderView.ACCOUNT, GraphRenderView.DUPLICATES].includes(graphView)) {
      setSearchFilterOption(
        searchFilterOption.map((option) => ({ option: option.label, value: true, disabled: false })),
      );
    }
    if ([GraphRenderView.LEADS].includes(graphView)) {
      //Enable only the leads filter option
      setSearchFilterOption(
        searchFilterOption.map((option) => ({
          option: option.label,
          value: option.label === 'Leads',
          disabled: option.label !== 'Leads',
        })),
      );
    }
  }, [graphView]);

  const handleEnableAll = useCallback(() => {
    if (graphView === GraphRenderView.LEADS) return;
    const inactiveFilters = searchFilterOption.filter((option) => option.active === false);
    setSearchFilterOption(inactiveFilters.map((option) => ({ option: option.label, value: true, disabled: false })));
    if (inactiveFilters.length > 0) {
      setLoading(true);
    }
    inactiveFilters.forEach((option) => {
      getFuzzySearch({
        migrationId: migrationId,
        tableId: option.tableName,
        label: option.label,
        options: { field: option.searchField, value: searchTerm, fallBackSearchField: option.fallBackSearchField },
      });
    });
  }, [getFuzzySearch, graphView, migrationId, searchFilterOption, searchTerm, setLoading, setSearchFilterOption]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'neutral.white',
          borderRadius: '3px',
        }}
        ref={searchBoxRef}
      >
        <SearchIcon style={{ color: 'action.active', marginLeft: 10, marginTop: 'auto', marginBottom: 'auto' }} />
        <TextField
          autoComplete="off"
          className={classes.removeFocusBorder}
          id="searchTerm"
          variant="standard"
          value={searchTerm}
          onFocus={() => handleSetShowSearchDetail(true)}
          onClick={() => {
            if (!showSearchDetail) {
              handleSetShowSearchDetail(true);
            }
          }}
          // onMouseEnter={() => handleSetShowSearchDetail(true)}
          onChange={(event) => {
            setSearchTerm(event.currentTarget.value);
          }}
          placeholder={placeholder}
          sx={{ my: 'auto', width: '100%' }}
        />

        {searchTerm && showSearchDetail && (
          <CrossIcon
            onClick={() => {
              setShowSearchDetail(false);
              // setSearchTerm('');
            }}
            style={{
              color: 'action.active',
              marginRight: 15,
              cursor: 'pointer',
            }}
          />
        )}
      </Box>
      <Box
        display={showSearchDetail ? 'block' : 'none'}
        onMouseEnter={() => handleSetShowSearchDetail(true)}
        // onMouseLeave={() => setShowSearchDetail(false)}
        sx={{
          position: 'absolute',
          width: `${searchBarWidth}px`,
          backgroundColor: 'neutral.white',
          maxHeight: '80vh',
          overflow: 'scroll',
          overflowX: 'hidden',
          px: 2,
          pb: 2,
          pt: 1,
          zIndex: 10,

          '&::-webkit-scrollbar-thumb': {
            borderRadius: '1px',
            background: theme.palette.neutral.darkBlueMedium,
          },
        }}
      >
        <Divider></Divider>
        <Box>
          <Grid container display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ py: 1 }}>
            <Grid xs={6} item>
              <Typography variant="labelRegular12" sx={{ color: theme.palette.neutral.darkGray }}>
                Filter by
              </Typography>
            </Grid>
            <Grid xs={6} item sx={{ textAlign: 'right' }}>
              <Box
                width={'fit-content'}
                sx={{ marginLeft: 'auto', cursor: 'pointer', ':hover': { textDecoration: 'underline' } }}
                onClick={handleEnableAll}
              >
                <Typography variant="labelRegular12" sx={{ color: theme.palette.primary.main }}>
                  Enable all
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container display={'flex'} flexDirection={'row'} sx={{ pb: 2 }} gap={1}>
            {searchFilterOption &&
              searchFilterOption.map((option) => {
                if (option.disabled === true) return null;
                return (
                  <Grid item key={`FilterChip-GraphRender-${option.label}`}>
                    <FilterChip active={option.active} text={option.label} option={option}></FilterChip>
                  </Grid>
                );
              })}
          </Grid>
        </Box>

        {searchFilterOption &&
          searchFilterOption.map((option) => {
            if (!option.active || option.disabled === true) return;
            return (
              <Box key={`FilterView-GraphRender-${option.label}`}>
                <Divider></Divider>
                <Box>
                  <Grid
                    container
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    sx={{ py: 1 }}
                  >
                    <Grid item>
                      <Typography variant="labelRegular12" sx={{ color: theme.palette.neutral.darkGray }}>
                        {option.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  {option.loading === true ? (
                    <LinearProgress />
                  ) : searchFilterResult[option.label]?.length > 0 ? (
                    <List>
                      {searchFilterResult[option.label].map((record) => {
                        return (
                          <ResultListItem
                            record={record}
                            key={`list-item-fuzzy-search-${record.Id}`}
                            option={option}
                            setShowSearchDetail={handleSetShowSearchDetail}
                          />
                        );
                      })}
                    </List>
                  ) : (
                    <>
                      <Box sx={{ textAlign: 'center', pb: 2 }}>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="labelMedium14"
                          color={theme.palette.neutral.main}
                        >
                          No matching records found!
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default SearchDropdown;
