import { Button, Grid, IconButton } from '@mui/material';
import theme from 'core/theme';
import { Position, NodeProps, NodeToolbar } from 'reactflow';
import { ReactComponent as LockIcon } from 'assets/icons/graph/lock.svg';
import { ReactComponent as UnLockIcon } from 'assets/icons/graph/unlock.svg';
import { useCallback, useMemo } from 'react';
import { useGraphRenderLayoutUtils } from '../../../hooks/utils/useGraphRenderLayoutUtils';
import { GraphRenderView } from 'store/graphRender/types';
import { useGraphRender } from 'store/graphRender/hooks';

interface ToolBarProps {
  nodeProps: NodeProps;
  renderShowActivities?: boolean;
  renderLockUnlockWhiteEdgeButton?: boolean;
}

export const ToolBar = (props: ToolBarProps) => {
  const { nodeProps, renderLockUnlockWhiteEdgeButton, renderShowActivities } = props;
  const { selected, id, data } = nodeProps;
  const { _collapsed } = data;
  const collapsed = _collapsed == null ? true : _collapsed;

  const {
    data: { view: graphView, whiteEdgeBlockNodeId },
    setWhiteEdgeBlockedNodeId,
  } = useGraphRender();

  const shouldRenderToolbar = useMemo(
    () => [GraphRenderView.ACCOUNT, GraphRenderView.LEADS].includes(graphView),
    [graphView],
  );

  const shouldRenderLockUnlockWhiteEdgeButton = useMemo(() => {
    return renderLockUnlockWhiteEdgeButton && (!whiteEdgeBlockNodeId || whiteEdgeBlockNodeId === id);
  }, [id, renderLockUnlockWhiteEdgeButton, whiteEdgeBlockNodeId]);

  const { showNodeActivities } = useGraphRenderLayoutUtils();

  const handleShowEvents = useCallback(() => {
    showNodeActivities(id, !collapsed, { desiredEdgedLength: 35 });
  }, [collapsed, id, showNodeActivities]);

  const handleLockUnlockView = useCallback(() => {
    if (whiteEdgeBlockNodeId) {
      setWhiteEdgeBlockedNodeId('');
    } else {
      setWhiteEdgeBlockedNodeId(id);
    }
  }, [id, setWhiteEdgeBlockedNodeId, whiteEdgeBlockNodeId]);

  return (
    <NodeToolbar isVisible={shouldRenderToolbar && (selected || whiteEdgeBlockNodeId === id)} position={Position.Top}>
      <Grid sx={{ display: 'flex', gap: 1 }}>
        {renderShowActivities && (
          <Button size="small" variant="outlined" onClick={handleShowEvents}>
            {collapsed ? 'Show Activities' : 'Hide Activities'}
          </Button>
        )}
        {shouldRenderLockUnlockWhiteEdgeButton && (
          <IconButton size="small" onClick={handleLockUnlockView}>
            {!whiteEdgeBlockNodeId ? (
              <UnLockIcon width={'24px'} height={'24px'} fill={theme.palette.primary.main} />
            ) : (
              <LockIcon width={'24px'} height={'24px'} fill={theme.palette.primary.main} />
            )}
          </IconButton>
        )}
      </Grid>
    </NodeToolbar>
  );
};
