import * as React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import IntegrationCard from './components/IntegrationCard';
import ProgressIconButton from './components/ProgressIconButton';
import ProgressActions from './components/ProgressActions';
import { LINEAR_PROGRESS_WIDTH } from './components/Constants';
import CustomerCityLogo from 'assets/GraphSpace/logo192.png';

interface HiddenButtons {
  step: string;
  isHidden?: boolean;
}

type ConfirmationProps = {
  onHiddenButtons: (data: HiddenButtons) => void;
};

const Confirmation: React.FC<ConfirmationProps> = ({ onHiddenButtons }) => {
  const [loadingIcon, setLoadingIcon] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    onHiddenButtons({ step: 'Confirmation', isHidden: true });
    const interval = setInterval(() => {
      setProgress((prev) => (prev >= 100 ? 100 : prev + 10));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setLoadingIcon(true);
    }
  }, [progress]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" color="neutral.main" my={2}>
        Your Salesforce data is integrating to Customer City
      </Typography>
      <Typography variant="p12" color="neutral.n400">
        {loadingIcon
          ? 'Your Salesforce data is now integrated to Customer City'
          : 'This integration can take a few minutes'}
      </Typography>
      <Typography variant="p12" color="neutral.n400">
        {loadingIcon
          ? 'You can stop or remove this integration at ControlTower'
          : 'In the mean time, you can stay on this screen or set up a new data source.'}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 4 }}>
        <IntegrationCard
          logoSrc="https://cdn-icons-png.flaticon.com/128/5968/5968914.png"
          name="Salesforce"
          isHighlighted={progress === 100}
        />
        <ProgressIconButton isComplete={loadingIcon} />
        <IntegrationCard logoSrc={CustomerCityLogo} name="Customer City" isHighlighted={progress === 100} />
      </Box>

      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{ width: '100%', maxWidth: LINEAR_PROGRESS_WIDTH, mt: 2, mb: 4 }}
      />

      <ProgressActions isComplete={loadingIcon} onNewSourceClick={() => console.log('Integrate new data source')} />
    </Box>
  );
};

export default Confirmation;
