import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getMigrationTableDataById as getMigrationTableDataByIdApi,
  getMigrationTableFuzzySearch as getMigrationTableFuzzySearchApi,
} from 'http/migration';

import { FunctionValue, GroupCountResponse } from 'http/migration/dto';

import {
  AccountPanelMenuViews,
  BridgePathMapByRecordId,
  GetFuzzySearchParams,
  GetMigrationTableRecordsParams,
  GraphObjectCounter,
  GraphPreloadData,
  GraphRenderView,
  MapObject,
  PartialUpdateGraphFilterParams,
  RecordReference,
  SearchFilterOptionUpdate,
  UpdatePayload,
} from './types';
import { GraphEdge, GraphFilter, GraphObject, GraphPathRecord } from 'store/graphData/types';
import { ComponentDesign } from 'pages/DataGraph/components/GraphRender/types';

const SET_ERROR = 'graphRender/SET_ERROR';
const SET_SUCCESS = 'graphRender/SET_SUCCESS';
const SET_LOADING = 'graphRender/SET_LOADING';
const SET_HOVER_NODE_ID = 'graphRender/SET_HOVER_NODE_ID';
const SET_OPEN_MERGE_MODAL = 'graphRender/SET_OPEN_MERGE_MODAL';
const SET_SHOW_CREATE_ACTIVITY_MODAL = 'graphRender/SET_SHOW_CREATE_ACTIVITY_MODAL';
const SET_SHOW_RECORD_PANEL = 'graphRender/SET_SHOW_RECORD_PANEL';
const SET_SHOW_SEARCH_DETAIL = 'graphRender/SET_SHOW_SEARCH_DETAIL';
const SET_RECORDS_TO_MERGE = 'graphRender/SET_RECORDS_TO_MERGE';
const SET_RENDER_DUPLICATE_EDGES_ON_HOVER = 'graphRender/SET_RENDER_DUPLICATE_EDGES_ON_HOVER';
const SET_RENDER_DUPLICATE_NODES_ON_HOVER = 'graphRender/SET_RENDER_DUPLICATE_NODES_ON_HOVER';
const SET_DUPLICATION_COUNTER_MAP = 'graphRender/SET_DUPLICATION_COUNTER_MAP';
const SET_DEFAULT_DESIGN = 'graphRender/SET_DEFAULT_DESIGN';
const SET_SCHEMA_NAME = 'graphRender/SET_SCHEMA_NAME';
const SET_MASTER_RECORD_IDS = 'graphRender/SET_MASTER_RECORD_IDS';
const SET_SHOW_DUPLICATION_SNACKBAR = 'graphRender/SET_SHOW_DEDUPLICATION_SNACKBAR';
const SET_DUPLICATED_NODE_IDS = 'graphRender/SET_DUPLICATED_NODE_IDS';
const SET_SHOW_NEPTUNE_STATUS_MODAL = 'graphRender/SET_SHOW_NEPTUNE_STATUS_MODAL';
const SET_DUPLICATION_PRELOAD_DATA = 'graphRender/SET_DEDUPLICATION_PRELOAD_DATA';
const SET_GRAPH_VIEW = 'graphRender/SET_GRAPH_VIEW';
const GET_MIGRATION_TABLE_RECORD = 'graphRender/GET_MIGRATION_TABLE_RECORD';
const SET_RENDER_NODES = 'graphRender/SET_RENDER_NODES';
const SET_RENDER_EDGES = 'graphRender/SET_RENDER_EDGES';
const SET_RENDER_COMPONENTS = 'graphRender/SET_RENDER_COMPONENTS';
const SET_NODES_HASHMAP = 'graphRender/SET_NODES_HASHMAP';
const SET_EDGES_HASHMAP = 'graphRender/SET_EDGES_HASHMAP';
const ADD_TO_NODES_HASHMAP = 'graphRender/ADD_TO_NODE_HASHMAP';
const ADD_TO_EDGES_HASHMAP = 'graphRender/ADD_TO_EDGES_HASHMAP';
const UPDATE_NODES = 'graphRender/UPDATE_NODES';
const UPDATE_EDGES = 'graphRender/UPDATE_EDGES';
const CLEAN_STATE = 'graphRender/CLEAN_STATE';
const SET_SEARCH_FILTER_OPTION = 'graphRender/SET_SEARCH_FILTER_OPTION';
const GET_FUZZY_SEARCH = 'graphRender/GET_FUZZY_SEARCH';
const CLEAN_FILTER_DATA = 'graphRender/CLEAN_FILTER_DATA';
const SET_SEARCH_TERM = 'graphRender/SET_SEARCH_TERM';
const SET_EDGES_TO_EXPAND = 'graphRender/SET_EDGES_TO_EXPAND';
const SET_CUSTOM_DESIGN_MAP = 'graphRender/SET_CUSTOM_DESIGN_MAP';
const SET_SELECTED_NODE = 'graphRender/SET_SELECTED_NODE';
const SET_WHITE_EDGE_BLOCKED_NODE_ID = 'graphRender/SET_WHITE_EDGE_BLOCKED_NODE_ID';
const SET_SELECTED_NODE_FROM_SEARCHBAR = 'graphRender/SET_SELECTED_NODE_FROM_SEARCHBAR';
const SET_ACCOUNT_HOVER_ID = 'graphRender/SET_ACCOUNT_HOVER_ID';
const SET_GRAPH_SEARCHING_RESULT = 'graphRender/SET_GRAPH_SEARCHING_RESULT';
const SET_GRAPH_ACCOUNT_VIEW_RESULT = 'graphRender/SET_GRAPH_ACCOUNT_VIEW_RESULT';
const SET_GRAPH_OPEN_LEAD_RESULT = 'graphRender/SET_GRAPH_OPEN_LEAD_RESULT';
const SET_GRAPH_OPEN_LEAD_RESULT_COUNTER = 'graphRender/SET_GRAPH_OPEN_LEAD_RESULT_COUNTER';
const SET_GRAPH_OBJECT_COUNTER = 'graphRender/SET_GRAPH_OBJECT_COUNTER';

//Graph filter Actions
const SET_GRAPH_FILTER = 'graphRender/SET_GRAPH_FILTER';
const PARTIAL_UPDATE_GRAPH_FILTER = 'graphRender/PARTIAL_UPDATE_GRAPH_FILTER';
const SET_QUICK_GRAPH_FILTER = 'graphRender/SET_QUICK_GRAPH_FILTER';
const PARTIAL_UPDATE_QUICK_GRAPH_FILTER = 'graphRender/PARTIAL_UPDATE_QUICK_GRAPH_FILTER';
const SET_CUSTOM_GRAPH_FILTER = 'graphRender/SET_CUSTOM_GRAPH_FILTER';
const PARTIAL_UPDATE_CUSTOM_GRAPH_FILTER = 'graphRender/PARTIAL_UPDATE_CUSTOM_GRAPH_FILTER';

export const setGraphFilter = createAction<GraphFilter>(SET_GRAPH_FILTER);
export const partialUpdateGraphFilter = createAction<PartialUpdateGraphFilterParams>(PARTIAL_UPDATE_GRAPH_FILTER);
export const setQuickGraphFilter = createAction<GraphFilter>(SET_QUICK_GRAPH_FILTER);
export const partialUpdateQuickGraphFilter = createAction<PartialUpdateGraphFilterParams>(
  PARTIAL_UPDATE_QUICK_GRAPH_FILTER,
);
export const setGraphCustomFilter = createAction<GraphFilter>(SET_CUSTOM_GRAPH_FILTER);
export const partialUpdateCustomGraphFilter = createAction<PartialUpdateGraphFilterParams>(
  PARTIAL_UPDATE_CUSTOM_GRAPH_FILTER,
);

//Lead view Actions
const SET_LEAD_VIEW_INITIAL_GROUP_DATA = 'graphRender/SET_LEAD_VIEW_INITIAL_DATA';
const SET_LEAD_VIEW_GROUP_BY_FIELDS = 'graphRender/SET_LEAD_VIEW_GROUP_BY_FIELDS';
const SET_LEAD_VIEW_GROUP_BY_FIELDS_SELECTED = 'graphRender/SET_LEAD_VIEW_GROUP_BY_FIELDS_SELECTED';
const SET_LEAD_VIEW_LOADED_BRIDGED_NODE = 'graphRender/SET_LEAD_VIEW_LOADED_BRIDGED_NODE';
const CLEAR_LEAD_VIEW_LOADED_BRIDGED_NODE = 'graphRender/CLEAR_LEAD_VIEW_LOADED_BRIDGED_NODE';
const SET_LEAD_VIEW_SORT_PANEL_SHOW = 'graphRender/SET_LEAD_VIEW_SORT_PANEL_SHOW';
const SET_LEAD_VIEW_DATE_FIELDS = 'graphRender/SET_LEAD_VIEW_DATE_FIELDS';
const SET_LEAD_VIEW_SORT_BY_FIELD = 'graphRender/SET_LEAD_VIEW_SORT_BY_FIELD';
const SET_LEAD_VIEW_SET_EXPAND_SEARCH_NODES = 'graphRender/SET_LEAD_VIEW_SET_EXPAND_SEARCH_NODES';

export const setLeadViewSetExpandSearchNodes = createAction<BridgePathMapByRecordId>(
  SET_LEAD_VIEW_SET_EXPAND_SEARCH_NODES,
);
export const setLeadViewInitialGroupData = createAction<GroupCountResponse[]>(SET_LEAD_VIEW_INITIAL_GROUP_DATA);
export const setLeadViewGroupByFields = createAction<string[]>(SET_LEAD_VIEW_GROUP_BY_FIELDS);
export const setLeadViewLoadedBridgeNode = createAction<{ key: string; value: boolean }>(
  SET_LEAD_VIEW_LOADED_BRIDGED_NODE,
);

export const clearLeadViewLoadedBridgeNode = createAction<void>(CLEAR_LEAD_VIEW_LOADED_BRIDGED_NODE);

export const setLeadViewGroupByFieldsSelected = createAction<(string | FunctionValue)[]>(
  SET_LEAD_VIEW_GROUP_BY_FIELDS_SELECTED,
);
export const setLeadViewSortPanelShow = createAction<boolean>(SET_LEAD_VIEW_SORT_PANEL_SHOW);
export const setLeadViewDateFields = createAction<string[]>(SET_LEAD_VIEW_DATE_FIELDS);
export const setLeadViewSortByField = createAction<string>(SET_LEAD_VIEW_SORT_BY_FIELD);

//Panel Actions
const SET_SELECTED_ACCOUNT_PANEL_VIEW = 'graphRender/SET_SELECTED_ACCOUNT_PANEL_VIEW';
export const setSelectedAccountPanelView = createAction<AccountPanelMenuViews>(SET_SELECTED_ACCOUNT_PANEL_VIEW);

export const setError = createAction<string | boolean>(SET_ERROR);

export const setSuccess = createAction<string | boolean>(SET_SUCCESS);

export const setLoading = createAction<boolean>(SET_LOADING);

export const setRecordsToMerge = createAction<RecordReference[]>(SET_RECORDS_TO_MERGE);

export const setGraphObjectCounter = createAction<GraphObjectCounter>(SET_GRAPH_OBJECT_COUNTER);

export const setShowRecordPanel = createAction<boolean>(SET_SHOW_RECORD_PANEL);

export const setShowCreateActivityModal = createAction<boolean>(SET_SHOW_CREATE_ACTIVITY_MODAL);

export const setShowSearchDetail = createAction<boolean>(SET_SHOW_SEARCH_DETAIL);

export const setGraphSearchResult = createAction<GraphPathRecord>(SET_GRAPH_SEARCHING_RESULT);

export const setOpenMergeModal = createAction<boolean>(SET_OPEN_MERGE_MODAL);

export const setGraphAccountViewResult = createAction<GraphPathRecord>(SET_GRAPH_ACCOUNT_VIEW_RESULT);

export const setGraphOpenLeadResult = createAction<GraphObject[]>(SET_GRAPH_OPEN_LEAD_RESULT);

export const setGraphOpenLeadResultCounter = createAction<number>(SET_GRAPH_OPEN_LEAD_RESULT_COUNTER);

export const setMasterRecordIds = createAction<string[]>(SET_MASTER_RECORD_IDS);

export const setRenderDuplicatedEdgesOnHover = createAction<string[]>(SET_RENDER_DUPLICATE_EDGES_ON_HOVER);

export const setRenderDuplicatedNodesOnHover = createAction<string[]>(SET_RENDER_DUPLICATE_NODES_ON_HOVER);

export const setDuplicationCounterMap = createAction<MapObject<number>>(SET_DUPLICATION_COUNTER_MAP);

export const setHoverNodeId = createAction<string>(SET_HOVER_NODE_ID);

export const setDefaultDesign = createAction<ComponentDesign>(SET_DEFAULT_DESIGN);

export const setDuplicatedNodeIds = createAction<string[]>(SET_DUPLICATED_NODE_IDS);

export const setSchemaName = createAction<string>(SET_SCHEMA_NAME);

export const setShowDuplicationSnackBar = createAction<boolean>(SET_SHOW_DUPLICATION_SNACKBAR);

export const setShowNeptuneStatusModal = createAction<boolean>(SET_SHOW_NEPTUNE_STATUS_MODAL);

export const setDeduplicationPreloadData = createAction<GraphPreloadData | undefined>(SET_DUPLICATION_PRELOAD_DATA);

export const setGraphView = createAction<GraphRenderView>(SET_GRAPH_VIEW);

export const setRenderNodes = createAction<MapObject<GraphObject>>(SET_RENDER_NODES);

export const setRenderComponents = createAction<{ nodes?: MapObject<GraphObject>; edges?: MapObject<GraphEdge> }>(
  SET_RENDER_COMPONENTS,
);

export const setAccountHoverId = createAction<string>(SET_ACCOUNT_HOVER_ID);

export const setSelectedNode = createAction<any>(SET_SELECTED_NODE);

export const setWhiteEdgeBlockedNodeId = createAction<string>(SET_WHITE_EDGE_BLOCKED_NODE_ID);

export const setSelectedNodeFromSearchBar = createAction<any>(SET_SELECTED_NODE_FROM_SEARCHBAR);

export const setRenderEdges = createAction<MapObject<GraphEdge>>(SET_RENDER_EDGES);

export const setNodesHashMap = createAction<MapObject<GraphObject>>(SET_NODES_HASHMAP);
export const addToNodesHashMap = createAction<MapObject<GraphObject>>(ADD_TO_NODES_HASHMAP);

export const setEdgesHashMap = createAction<MapObject<GraphEdge>>(SET_EDGES_HASHMAP);
export const addToEdgesHashMap = createAction<MapObject<GraphEdge>>(ADD_TO_EDGES_HASHMAP);

export const setCustomDesignMap = createAction<MapObject<ComponentDesign>>(SET_CUSTOM_DESIGN_MAP);

export const updateNodes = createAction<UpdatePayload[]>(UPDATE_NODES);

export const updateEdges = createAction<UpdatePayload[]>(UPDATE_EDGES);

export const cleanState = createAction(CLEAN_STATE);

export const cleanFilterData = createAction(CLEAN_FILTER_DATA);

export const setSearchTerm = createAction<string>(SET_SEARCH_TERM);

export const setEdgesToExpand = createAction<string[]>(SET_EDGES_TO_EXPAND);

export const setSearchFilterOption = createAction<SearchFilterOptionUpdate[]>(SET_SEARCH_FILTER_OPTION);

export const getMigrationTableRecord = createAsyncThunk<any[], GetMigrationTableRecordsParams>(
  GET_MIGRATION_TABLE_RECORD,
  async ({ migrationId, tableId, skip, take, conditions, onError, onSuccess }: GetMigrationTableRecordsParams) => {
    try {
      const res = await getMigrationTableDataByIdApi(migrationId, tableId, skip, take, conditions);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

export const getFuzzySearch = createAsyncThunk<any[], GetFuzzySearchParams>(
  GET_FUZZY_SEARCH,
  async ({ migrationId, tableId, options, onError, onSuccess }: GetFuzzySearchParams) => {
    try {
      const res = await getMigrationTableFuzzySearchApi(migrationId, tableId, options);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

//Duplicate Quick Filter Actions

const DUPLICATE_QUICK_FILTER_SET_SELECTED_TABLES = 'graphRender/DUPLICATE_QUICK_FILTER_SET_SELECTED_TABLES';
export const duplicateQuickFilterSetSelectedTables = createAction<string[]>(DUPLICATE_QUICK_FILTER_SET_SELECTED_TABLES);
